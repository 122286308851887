import { TextareaOutput } from "./styled";

const CodeBoxOutput = ({ data, style, classNames, config }) => {

  return (
    data.code
      ?
      <div className="codex-editor__redactor">
        <div className="ce-block ce-block--focused">
          <div className="cdx-block ce-code">
            <TextareaOutput defaultValue={data.code} disabled={true} className={ "ce-code__textarea"} placeholder="Enter a code">
            </TextareaOutput>
          </div>
        </div>

      </div>
      :
      null
  );
};

export default CodeBoxOutput;

