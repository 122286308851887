import styled from "styled-components";

const PaginationWrap = styled.div`
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
`;

export default PaginationWrap