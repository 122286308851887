import styled from "styled-components";
import { StyledButton } from ".";

const CopyButton = styled(StyledButton)`
  color: #163C85;
  background: #E8F1FF;
  background-color: transparent;
  display:flex;
  align-items:center;
  gap:20px;

  &:hover {
    background-color:#BFD8FF;
;
  }
`;

export default CopyButton