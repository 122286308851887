import styled from "styled-components";

const OverlayPopupWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    padding: 36px;
    box-sizing: border-box;

    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
`
export default OverlayPopupWrapper;