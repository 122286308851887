import React from 'react';
import { MainMenu } from 'ui/components/Menu';
import { MenuTitle, MenuWrapper, SidebarContainer } from './styled';

const MainSidebar = () => {
    return (
        <SidebarContainer>
            <MenuWrapper>
                <MenuTitle>
                    <h3>
                        Админпанель
                    </h3>
                    
                </MenuTitle>
                <MainMenu />
            </MenuWrapper>
        </SidebarContainer>
    );
};

export default MainSidebar;