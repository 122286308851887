import styled from "styled-components"

const RemoveButton = styled.button`
    color: #FF5F5F;
    cursor:pointer;
    padding:10px 20px;
    border-radius: 5px;
    
    &:hover {
        background:#FFE1E1
    }
`

export default RemoveButton