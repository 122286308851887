import { InputWrap, Overlay } from "routes/pages/GroupsPage/styled";
import { Form } from "../UserPopup/styled";
import { Text } from "../AdvancedSettingsPopup/styled";
import { IconClose } from "ui/components/IconClose";
import { InputDefault } from "ui/styled";
import { useState } from "react";
import { IconWrap } from "../ConfirmationPopup/styled";
import { RedButton } from "ui/components/Buttons";

const DenialPopup = ({ text, onClose, params, handleBanUser, el, type }) => {
    const [comment, setComment] = useState('')

    const handleBan = (e) => {
        e.preventDefault();
        handleBanUser(params, comment)
    }
    return (
        <>
            <Overlay />
            <Form>
                <IconWrap>
                    <p>{text}</p>
                    <button onClick={onClose}>
                        <IconClose />
                    </button>
                </IconWrap>
                <InputWrap>
                    <InputDefault onChange={(e) => setComment(e.target.value)} value={comment} />
                </InputWrap>
                <RedButton onClick={type === "user" ? (e) => handleBan(e) : (e) => handleBanUser(e, el.id, comment, el.moderationStatus)}>Отклонить</RedButton>
            </Form>
        </>
    );
}

export default DenialPopup;