import { Accordion, PlusIcon } from ".";
import { AddGroup, FormWrap, RemoveButton, Title } from "../styled";
import AddGroupWrap from "../styled/AddGroupWrap";
import ConfirmationModal from "ui/Popups/ConfirmationPopup";
import { useModal } from '../hooks';

const Form = ({ type, buttonRef, text, title }) => {
    const { isVisible: isModalCourses, openModal: openModalCourses, closeModal: closeModalCourses } = useModal();
    const { isVisible: isModalAccordion, openModal: openAccordionModal, closeModal: closeAccordionModal } = useModal();

    return (
        <>
            <FormWrap>
                <h4>{title}</h4>
                <AddGroupWrap onClick={openAccordionModal}>
                    <PlusIcon />
                    <AddGroup>{text}</AddGroup>
                </AddGroupWrap>
                <RemoveButton disabled onClick={openModalCourses}>Очистить все</RemoveButton>
                <Accordion
                    type={type}
                    handleCloseModal={closeAccordionModal}
                    showModal={isModalAccordion}
                    buttonRef={buttonRef}
                    text={text}
                />
            </FormWrap>

            {isModalCourses && (
                <ConfirmationModal
                    show={isModalCourses}
                    onClose={closeModalCourses}
                    onConfirm={closeModalCourses}
                    text="Точно хотите всё очистить?"
                />
            )}
        </>
    );
};

export default Form;
