import { LuCheckCircle2 } from "react-icons/lu";
import { CopyButton } from "ui/Popups/LinksPopup/styled";

const CopiedButton = () => {
    return (
        <CopyButton style={{background:'#41D27A',color:'white',display:'flex',gap:15 }}>
            <span>Скопировано!</span>
            <LuCheckCircle2 style={{fontSize:20}} />
        </CopyButton>
    )
}

export default CopiedButton;