import styled from "styled-components"

const Wrapper = styled.div`
   display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border-top: 1px solid #e8e8eb;
    padding-top: 20px;
`

export default Wrapper