export async function handleApiRequest(apiCall) {
    try {
        const { data } = await apiCall();

        return data;
    } catch (error) {
        console.error("API request failed:" + error);
    }
}

export function debounce(func, delay) {
    let timeoutId;

    return function executeFunction(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args)
        }, delay)
    }
} 