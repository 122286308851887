import styled from "styled-components";

const StyledButton = styled.button`
  padding: 16px 37px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
`;

export default StyledButton