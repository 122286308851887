import React from 'react';
import { NavLink } from 'ui/styled';
import { LogOutButton } from 'ui/components/Buttons';
import { MenuSection } from './styled';

const MainMenu = () => {

    return (
        <MenuSection>
            <NavLink to={'/home'}>
                <p>Главная</p>
            </NavLink>
            <NavLink to={'/docs'}>
                <p>Документы</p>
            </NavLink>
            <NavLink to={'/logs'}>
                <p>Логи</p>
            </NavLink>
            <NavLink to={'/moder'}>
                <p>Модерка</p>
            </NavLink>
            <NavLink to={'/users'}>
                <p>Юзеры</p>
            </NavLink>
            <NavLink to={'/suspicious'}>
                <p>Подозрительные транзакции</p>
            </NavLink>
            <NavLink to={'/groups'}>
                <p>Управление группами</p>
            </NavLink>
            <LogOutButton>
                Выход
            </LogOutButton>
        </MenuSection>
    );
};

export default MainMenu;