import React, { useState } from 'react';
import { LessonsWrapper } from '../styled';
import { LessonCard } from '.';
import { formatDate } from 'hooks';
import { BlueButton } from 'ui/components/Buttons';
import { CardDefault } from 'ui/components/Cards';

const ModuleCard = ({ el }) => {

    const [visibleLesson, setVisibleLesson] = useState(false)

    const fields = [
        { label: 'ID', value: el.id },
        { label: 'Дата создания', value: formatDate(el.createdAt) },
        { label: 'Дата изменения', value: formatDate(el.updatedAt) },
    ]

    return (
        <CardDefault
            {...el}
            label={el.name}
            fields={fields}
            buttons={
                <BlueButton $background={'#3da5ce'} $hover={'#45bae9'} onClick={() => setVisibleLesson(!visibleLesson)}>
                    {!visibleLesson ? 'Показать ' : 'Скрыть '} уроки
                </BlueButton>
            }
            accordion={
                visibleLesson ?
                    <LessonsWrapper>
                        {
                            el.lessons.length > 0
                                ? el?.lessons.map(lesson =>
                                    <LessonCard el={lesson} key={lesson.id}/>
                                )
                                : <p>Нет уроков</p>
                        }
                    </LessonsWrapper>
                    : null
            }
        />
    );
};

export default ModuleCard;