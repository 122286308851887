import React, { useEffect, useMemo, useState } from 'react';
import { PageWrap, PageHeader } from 'ui/styled';
import { CoursesList } from './components';
import Select from 'react-select';
import coursesStore from 'store/coursesStore';
import { observer } from 'mobx-react';
import { Pagination } from 'ui/components/Pagination';

const ModerPage = observer(() => {
    const [filter, setFilter] = useState({ value: 'all', label: 'Все курсы' });
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true);
            try {
                const { value } = filter;
                if (value === 'all') {
                    await coursesStore.loadCourses(currentPage);
                } else {
                    await coursesStore.loadCourses(currentPage, 10, true, value);
                }

                setLoading(false);
                setTotalPages(coursesStore.totalPages);
                console.log(coursesStore.totalPages)
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        };

        fetchCourses();
    }, [currentPage, filter]);

    const options = [
        { value: 'all', label: 'Все курсы' },
        { value: 1, label: 'На проверке' },
        { value: 0, label: 'Одобренные' },
        { value: 2, label: 'Отклонённые' },
        { value: 3, label: 'Новые' },
    ];

    if (error) {
        return <p>Произошла ошибка при загрузке курсов</p>;
    }


    return (
        <PageWrap>
            <PageHeader>
                <Select
                    options={options}
                    defaultValue={filter}
                    onChange={(selectedOption) => {
                        setFilter(selectedOption);
                        setCurrentPage(1);
                    }}
                />
            </PageHeader>
            <CoursesList filteredCourses={coursesStore.courses} loading={loading} />
            {
                coursesStore.courses.length !== 0 && (
                    <Pagination totalPages={totalPages} currentPage={currentPage} setIndex={setCurrentPage} />
                )
            }
        </PageWrap>
    );
});

export default ModerPage;
