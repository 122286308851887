import React from 'react';
import { Portal } from 'hoc';
import {
    Overlay,
    OverlayPopupWrapper
} from './styled'

const OverlayPopup = ({ children, onClose, isOpened }) => {

    if (!isOpened) {
        return null;
    }

    return (
        <Portal>
            <OverlayPopupWrapper role='dialog'>
                <Overlay
                    role='button'
                    tabIndex={0}
                    onClick={onClose}
                />
                {children}
            </OverlayPopupWrapper>
        </Portal>
    );
};

export default OverlayPopup;