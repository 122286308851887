import styled from "styled-components";

export const FileButtonChange = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    background-color: #ffffff;
    border: 2px solid 	 #ff8533;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    color:  #ff8533;
    font-weight: 500;
    transition: background-color 0.3s, border-color 0.3s;

    &:hover {
        background-color: #f4f4f4;
        border-color: 	 #ff751a;
    }
`