import styled from "styled-components";

export const FileInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  
  &:hover {
    cursor: pointer;
}
`;