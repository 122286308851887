import { api } from "api"
import config from "config"
import { data } from "./../mock/mockData"

export const getUsers = (page = 1, pageSize = 10, filter = false, is_banned = false) => {
    if (config.useMocks) {
        return new Promise((resolve) => {
            resolve({ data })
        })
    }
    if (filter) {
        return api.get(`${process.env.REACT_APP_API}/User/FilterUsers`, {
            params: {
                page: page,
                pageSize: pageSize,
                is_banned: is_banned
            }
        })
    }

    return api.get(`${process.env.REACT_APP_API}/User/FilterUsers`, {
        params: {
            page: page,
            pageSize: pageSize
        }
    })
}

export const usersNotGroup = (id, page = 1, pageSize = 10) => {
    return api.get(`${process.env.REACT_APP_API}/groups/UsersNotGroup`, {
        params: {
            groupId: id,
            page: page,
            pageSize: pageSize
        }
    })
}
export const banUser = (object, comment) => {
    if (comment) {
        return api.post(`${process.env.REACT_APP_API}/User/banuser/${object.id}/${object.status}`, {
            params: {
                comment: comment
            }
        })

    }
    return api.post(`${process.env.REACT_APP_API}/User/banuser/${object.id}/${object.status}`)
}

export const getLogsById = (id) => {
    return api.get(`logs/user/${id}`)
}