import styled from "styled-components";

const ContentWrapper = styled.section`
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    /* overflow-x: auto; */
    padding: 10px;
`

export default ContentWrapper