import styled from "styled-components";

const TableRow = styled.th`
    min-width: 100px;
    padding: 8px 15px;
    border-radius: 2px;
    text-align: left;
    border:1px solid #b4b4b4
`

export default TableRow;