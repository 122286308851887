import styled from "styled-components";

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #e4e4e4;
    background-color: #94b1c5;
    border-radius: 20px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
`