import styled from "styled-components"

const InputGroupName = styled.input`
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    border:none;
`;

export default InputGroupName