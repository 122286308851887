import React, { useState } from 'react';
import { GreenButton } from 'ui/components/Buttons';
import { Labels, LogContainerTheme, LogField } from '../styled';
import { LogsList } from '.';
import { IoSync } from "react-icons/io5";

const LogsTerminal = ({ items, setDate, reload }) => {

    const [themechange, setTheme] = useState('light');

    const handleThemeChange = () => {
        setTheme(themechange === 'light' ? 'dark' : 'light');
    };

    return (
        <>
            <div>
                <GreenButton onClick={handleThemeChange}>
                    Тема
                </GreenButton>
                <input type="date" onChange={(e) => setDate(e.target.value)} />
                <GreenButton onClick={reload}>
                    <IoSync />
                </GreenButton>
            </div>
            <LogContainerTheme theme={themechange}>
                <Labels>
                    <LogField>ID</LogField>
                    <LogField>User ID</LogField>
                    <LogField>Время (UTC)</LogField>
                    <LogField>IP адрес</LogField>
                    <LogField>URL запроса</LogField>
                </Labels>
            </LogContainerTheme >
            <LogContainerTheme theme={themechange}>
                {
                    items ? (
                        <LogsList items={items} themechange={themechange} />
                    ) : (
                        <div>  </div>
                    )
                }
            </LogContainerTheme>
        </>
    );
};

export default LogsTerminal;