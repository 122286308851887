import Output from 'editorjs-react-renderer';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import renderers from './components/renderers/CustomRerenders';
import { LessonArea } from './styled'
import { PageHeader,PageWrap } from 'ui/styled';
import { BlueButton } from 'ui/components/Buttons';

const ContentLessonPage = () => {

    const navigate = useNavigate()
    const location = useLocation()

    return (
        <PageWrap>
            <PageHeader>
                <BlueButton onClick={() => navigate(-1)}>
                    Назад
                </BlueButton>
            </PageHeader>
            <LessonArea>
                <Output
                    data={JSON.parse(location?.state)}
                    renderers={renderers}
                />
            </LessonArea>
        </PageWrap>
    );
};

export default ContentLessonPage;