import styled from "styled-components";

const LessonArea = styled.div`
    z-index: 0;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
`

export default LessonArea;