import styled from "styled-components";

const Indicator = styled.div`
    width: 3px;
    height: 70%;
    position: absolute;
    left: 8px;
    border-radius: 10px;
    background-color: #1d6acf 
`

export default Indicator