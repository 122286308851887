import styled from "styled-components";
import { LogContainer, LogItem, LogField, LogId, Labels } from '.';

const LogContainerTheme = styled(LogContainer)`
  background-color: ${(props) => props.theme === 'light' ? '#f7f7f7' : '#330000'};
  color: ${(props) => props.theme === 'light' ? '#333' : '#ffcccc'};

  ${LogItem} {
    background-color: ${(props) => props.theme === 'light' ? '#f7f7f7' : '#330000'};
    border: ${(props) => props.theme === 'light' ? '1px solid #ccc' : '1px solid #990000'}
  }

  ${LogField} {
    color: ${(props) => props.theme === 'light' ? '#333' : '#ffcccc'};
  }

  ${LogId} {
    color: ${(props) => props.theme === 'light' ? '#ff6666' : '#ffcccc'};
  }

  ${Labels} {
    color: ${(props) => props.theme === 'light' ? '#333' : '#ffcccc'};
  }

  ${LogItem}:hover {
    background-color: ${(props) => props.theme === 'light' ? '#f7f7f7' : '#660000'};
    color: ${(props) => props.theme === 'light' ? '#333' : '#ffcccc'};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme === 'light' ? '#f7f7f7' : 'transparent'}
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme === 'light' ? '#ddd' : '#979797'};
    cursor: pointer;
  }
`;

export default LogContainerTheme;