import { observer } from 'mobx-react';
import userStore from 'store/userStore';
import { Outlet, Navigate } from 'react-router-dom';

const HocPrivateRoute = observer(() => {

    if (!userStore.authIsReady) {
        return (
            <div style={{ width: '100wh', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                Loading...
            </div>
        )
    }

    if (userStore.auth) {
        return <Outlet />
    } else {
        return <Navigate to={'/login'} />;
    }

});

export default HocPrivateRoute;