import styled from "styled-components";

const ExtensiveSettingsWrap = styled.div`
    z-index: 1000;
    background-color: white; 
    padding: 20px; 
    border-radius: 20px; 
    display: flex; 
    flex-direction: column; 
    gap: 30px; 
    position: fixed;
    width: 400px;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
`

export default ExtensiveSettingsWrap;