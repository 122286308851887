import styled from 'styled-components'

const SubjectItem = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
`;

export default SubjectItem