import { useEffect, useState } from "react";
import { ArrowIconOutlited, Card, CardPopup, Filter, NotElements } from ".";
import { AccordionContainer, AccordionWrap, AccordionWrapper, ArrowIconWrapper, CoursesListContainer, CoursesListWrap, ModalContainer, Overlay, UsersListContainer } from "../styled";
import { observer } from "mobx-react";
import groupsStore from "store/groupsStore";
import { Pagination } from "ui/components/Pagination";

const Accordion = observer(({
    type,
    handleCloseModal,
    buttonRef,
    text,
    showModal,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [error, setError] = useState(null);
    const [value, setValue] = useState('');
    const [currentPageCourse, setCurrentPageCourse] = useState(1);
    const [currentPageUser, setCurrentPageUser] = useState(1)

    useEffect(() => {
        if (type === "users") {
            const fetchUsers = async () => {
                try {
                    await groupsStore.loadUsersNotGroup(groupsStore.selectedGroup.id, currentPageUser);
                } catch (error) {
                    setError(error);
                }
            };
            fetchUsers();
        } else if (type === "courses") {
            const fetchCourses = async () => {
                await groupsStore.loadCourseNotGroup(groupsStore.selectedGroup.id, currentPageCourse);
            }

            fetchCourses();
        }
    }, [type, currentPageUser, currentPageCourse]);

    const filteredUsers = groupsStore.users?.filter(user => user.user_name.toLowerCase().includes(value.toLowerCase()));
    const filteredCourses = groupsStore.courses?.filter(course => course.label.toLowerCase().includes(value.toLowerCase()))

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const fetchData = async (type, groupId) => {
        if (type === "user") {
            await groupsStore.loadUsersNotGroup(groupId);
        } else if (type === "course") {
            await groupsStore.loadCourseNotGroup(groupId);
        }
    };

    if (!showModal) return null;

    return (
        <>
            <Overlay onClick={handleCloseModal} />
            <AccordionWrapper>
                <AccordionContainer onClick={toggleExpand}>
                    <AccordionWrap value={value} onChange={(e) => setValue(e.target.value)} placeholder={text} />
                    <ArrowIconWrapper>
                        <ArrowIconOutlited rotated={isExpanded} />
                    </ArrowIconWrapper>
                </AccordionContainer>

                {isExpanded ? (
                    type === "courses" ? (
                        <ModalContainer>
                            <CoursesListContainer>
                                {
                                    filteredCourses?.length !== 0
                                        ? filteredCourses?.map((courseGroup) => (
                                            <CardPopup
                                                fetchData={fetchData}
                                                key={courseGroup.courseId}
                                                item={courseGroup}
                                                type="course"
                                            />
                                        ))
                                        : <p style={{ textAlign: 'center' }}>По вашему запросу ничего не найдено</p>
                                }
                            </CoursesListContainer>
                            <Pagination currentPage={currentPageCourse} setIndex={setCurrentPageCourse} totalPages={groupsStore.totalCourses} />
                            <Filter
                                id={groupsStore.selectedGroup.id}
                                width="10px"
                                height="10px"
                                filtered={true}
                                handleCloseModal={handleCloseModal}
                                toggleExpand={toggleExpand}
                                buttonRef={buttonRef}
                            />
                        </ModalContainer>
                    ) : (
                        <ModalContainer>
                            <UsersListContainer>
                                {
                                    filteredUsers?.length !== 0
                                        ? filteredUsers?.map((userGroup, index) => (
                                            <CardPopup
                                                fetchData={fetchData}
                                                key={`${groupsStore.selectedGroup.id}-${userGroup.id}-${index}`}
                                                item={userGroup}
                                                type="user"
                                            />
                                        ))
                                        : <NotElements text={"По вашему запросу ничего не найдено"} />
                                }
                            </UsersListContainer>
                            <Pagination currentPage={currentPageUser} setIndex={setCurrentPageUser} totalPages={groupsStore.totalUsers} />
                            <Filter
                                width="5px"
                                height="10px"
                                filtered={false}
                                handleCloseModal={handleCloseModal}
                                toggleExpand={toggleExpand}
                                buttonRef={buttonRef}
                            />
                        </ModalContainer>
                    )
                ) : (
                    ""
                )}
            </AccordionWrapper>
        </>
    );
});

export default Accordion;
