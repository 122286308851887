import styled from "styled-components";

const AccordionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    border-radius: 10px;
    background-color: #f7f8fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

export default AccordionContainer;