import styled from "styled-components";

export const Table = styled.table`
border-radius: 8px;
width: 100%;
border-collapse: separate;
border-spacing:0;
overflow:hidden;
border: 1px solid #ddd;
th, td {
    padding: 8px;
    text-align: left;
    border: none; 
}
th {
    border-bottom: 1px solid #ddd; 
}
tr:not(:last-child) td {
    border-bottom: 1px solid #ddd; 
}
td:nth-last-child(-n+2) {
    text-align: center;
}
`