import styled from "styled-components";

const MenuSection = styled.nav`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 2px;
`;

export default MenuSection;