const IconClose = () => {
    return (
        <svg style={{ cursor: 'pointer' }} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="14" fill="#DDDDDD" />
            <path d="M15.3856 13.3927L21 19.0068L26.5853 13.4218C26.7087 13.2905 26.8573 13.1854 27.0222 13.113C27.1872 13.0405 27.3651 13.0021 27.5453 13C27.931 13 28.301 13.1532 28.5738 13.426C28.8465 13.6988 28.9998 14.0687 28.9998 14.4544C29.0032 14.6328 28.9701 14.8099 28.9025 14.975C28.835 15.14 28.7345 15.2896 28.607 15.4144L22.949 20.9994L28.607 26.6572C28.8468 26.8917 28.9873 27.2093 28.9998 27.5444C28.9998 27.9302 28.8465 28.3001 28.5738 28.5729C28.301 28.8456 27.931 28.9989 27.5453 28.9989C27.3599 29.0065 27.1749 28.9756 27.0022 28.908C26.8294 28.8404 26.6726 28.7376 26.5417 28.6062L21 22.992L15.4002 28.5916C15.2773 28.7185 15.1304 28.8199 14.9682 28.8898C14.8059 28.9597 14.6314 28.9967 14.4547 28.9989C14.069 28.9989 13.699 28.8456 13.4263 28.5729C13.1535 28.3001 13.0002 27.9302 13.0002 27.5444C12.9968 27.3661 13.0299 27.189 13.0975 27.0239C13.165 26.8588 13.2655 26.7093 13.393 26.5845L19.051 20.9994L13.393 15.3416C13.1532 15.1071 13.0127 14.7896 13.0002 14.4544C13.0002 14.0687 13.1535 13.6988 13.4263 13.426C13.699 13.1532 14.069 13 14.4547 13C14.8038 13.0044 15.1384 13.1454 15.3856 13.3927Z" fill="#707070" />
        </svg>

    )
}

export default IconClose