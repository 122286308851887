import React, { useEffect, useState } from 'react';
import { LogWrapper } from './styled';
import { LogsTerminal } from './ui';
import { getLogs } from './api';

const LogsPage = () => {

    const [logs, setLogs] = useState([])
    const [date, setDate] = useState('');

    useEffect(() => {
        requestData()
    }, [date])

    const requestData = () => {
        getLogs({ params: { date: date ? date : null } })
            .then((result) => {
                if (result.data) {
                    setLogs(result.data);
                }
            })
    }

    return (
        <LogWrapper>
            <LogsTerminal items={logs} setDate={setDate} reload={requestData} />
        </LogWrapper>
    );
};

export default LogsPage;