import { AddGroup, ButtonsWrap, CrossWrap, InputDefaultWrap, InputEdit, SaveButton } from "../styled";
import { RxCross1 } from "react-icons/rx";
import { FaEdit } from "react-icons/fa";
import groupsStore from "store/groupsStore";
import { useEditGroup } from "../hooks";

const InputEditing = ({ isEditing, startEditing, stopEditing, saveGroup, groupName }) => {

    const handleSaveEdit = async () => {
        try {
            await groupsStore.updateGroup(groupsStore.selectedGroup.id, {
                ...groupsStore.selectedGroup,
                name: groupName
            });

            stopEditing();
        } catch (error) {
            console.error('Ошибка при обновлении группы', error);
        }
    };
    return (
        <>
            {isEditing ? (
                <InputDefaultWrap>
                    <InputEdit
                        value={groupName}
                        onChange={(e) => saveGroup(e.target.value)}
                        autoFocus
                    />
                    <ButtonsWrap>
                        <SaveButton onClick={handleSaveEdit}>Сохранить</SaveButton>
                        <CrossWrap onClick={stopEditing}>
                            <RxCross1 />
                        </CrossWrap>
                    </ButtonsWrap>
                </InputDefaultWrap>
            ) : (
                <AddGroup>{groupName}</AddGroup>
            )}
            {!isEditing && (
                <FaEdit style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={startEditing} />
            )}
        </>
    )
}

export default InputEditing;