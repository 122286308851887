import styled from "styled-components";

const SettingWrap = styled.ul`
    position: absolute;
    top: -150%;
    left: -250%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    list-style-type: none;
    z-index: 1000;
    background-color: white;
    border-radius:10px;
    justify-content:center;
    align-items:center;
`

export default SettingWrap