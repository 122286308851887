import React, { useState } from 'react';
import { formatDate } from 'hooks';
import { GreenButton, RedButton } from 'ui/components/Buttons';
import { CardDefault } from 'ui/components/Cards';
import { changeStatusLesson } from '../api';
import MainPopup from 'ui/Popups/MainPopup';
import { useNavigate } from 'react-router-dom';

const LessonCard = ({ el }) => {

    const navigate = useNavigate()
    const goContent = () => navigate(`/content/lesson/${el.id}`, { state: el.content })
    const [error, setError] = useState({ status: false, message: 'Ошибка запроса' })
    const [fields, setFields] = useState([
        { label: 'ID', value: el.id },
        { label: 'Дата создания', value: formatDate(el.createdAt) },
        { label: 'Дата изменения', value: formatDate(el.updatedAt) },
        {
            label: 'Статус модерации',
            value: el.moderationStatus === 3
                ? 'Новый' : el.moderationStatus === 2
                    ? 'Отказ' : el.moderationStatus === 1
                        ? 'На проверке' : el.moderationStatus === 0
                            ? 'Разрешён'
                            : null
        },
        { label: 'Контент', value: el.content ? <GreenButton onClick={() => goContent()}>Контент</GreenButton> : 'Пусто' },
    ])

    return (
        <CardDefault
            {...el}
            label={el.name}
            fields={
                fields
            }
            buttons={
                <>
                    <GreenButton onClick={() => changeStatusLesson({ LessonsId: el.id, Status: 0 })
                        .then(() => {
                            setFields(prevState => prevState.map(e => {
                                if (e.label === 'Статус модерации') {
                                    return { ...e, value: 'Разрешён' }
                                } return e
                            }))
                        })
                        .catch(() => setError({ ...error, status: true }))}>
                        Принять
                    </GreenButton>
                    <RedButton onClick={() => changeStatusLesson({ LessonsId: el.id, Status: 2 })
                        .then(() => {
                            setFields(prevState => prevState.map(e => {
                                if (e.label === 'Статус модерации') {
                                    return { ...e, value: 'Отказ' }
                                } return e
                            }))
                        })
                        .catch(() => setError({ ...error, status: true }))}>
                        Отклонить
                    </RedButton>
                </>
            }
            popup={
                <MainPopup isOpened={error.status} onClose={() => setError({ ...error, status: false })}>
                    <h3>{error.message}</h3>
                </MainPopup>
            }
        />
    );
};

export default LessonCard;