import React from 'react';
import { Button } from 'ui/styled';

const BlueButton = ({ background, hover, children, ...props }) => {
    return (
        <Button
            $background={background ? background : '#3da5ce'}
            $hover={hover ? hover : '#45bae9'}
            {...props}
        >
            {children}
        </Button >
    );
};

export default BlueButton;