import styled from "styled-components"

const ButtonClose = styled.button`
    color:#163C85;
    font-size:18px;
    cursor:pointer;
    padding:12px 30px;
    border-radius:10px;
    transition:background .3s  ease-in-out;

    &:hover {
        background:#BFD8FF;
    }
`

export default ButtonClose