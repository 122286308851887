import { api } from "api";
import { handleApiRequest } from "./../../../../helpers/functions"
export const updateGroup = (id, updatedData) => {
    return api.put(`groups/update/${id}`, updatedData);
};

export const loadGroups = (pageNumber, pageSize) => {
    return (
        handleApiRequest(() =>
            api.get(`groups/all`, {
                params: {
                    pageSize: pageSize,
                    pageNumber: pageNumber
                }
            })
        )
    )
}

export const addNewGroup = (newGroup) => {
    return api.post('groups/create', { name: newGroup })
}

export const deleteGroup = (groupId) => {
    return api.delete(`groups/delete/${groupId}`)
}

export const copyLink = (id) => {
    return (
        handleApiRequest(() =>
            api.post(`groups/create-link`, { Id: id })
        )
    )
}

export const deleteLink = (groupId) => {
    return api.delete(`groups/delete-link-by-group/${groupId}`);
}

export const addNewCourse = (groupId, courseId) => {
    return api.post(`groups/add-course-to-group`,
        {
            groupId: groupId, courseId: courseId
        }
    );
}

export const deleteCourse = (groupId, courseId) => {
    return api.delete(`groups/remove-course-from-group`, {
        params: {
            courseId: courseId,
            groupId: groupId
        }
    });
}

export const addNewUser = (groupId, userId) => {
    return api.post(`groups/add-user-to-group`, null, {
        params: {
            groupId: groupId,
            userId: userId
        }
    });
}

export const deleteUser = (groupId, userId) => {
    return api.delete(`groups/remove-user-from-group`, {
        params: {
            groupId: groupId,
            userId: userId
        }
    });
}

export const coursesNotGroupFilters = (id, updatedSelectedSubjects) => {
    return api.get(`/groups/CoursesNotGroup`, {
        params: {
            groupId: id,
            filters: { subjectsId: updatedSelectedSubjects }
        }
    })
}