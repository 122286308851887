export const data = {
    "totalPages": 1,
    "users": [
        {
            "id": 1,
            "mail": "tnv23@pcruss.ru",
            "password": "204660d39460da10199ba96317effa8e58a044857a07c6b24811eefc1ba64366",
            "salt": "BCmPmxe1CWOlEVcVNAmKqOf8zXC2zKf/UEDdF9bHizg=",
            "current_folder_size": 0,
            "confirmed": true,
            "role_id": 2,
            "user_name": "Николай",
            "user_surname": "Толстоухов",
            "user_patronymic": "Владимирович",
            "user_phone_number": null,
            "user_nikname": null,
            "user_birthday": "2000-10-09T15:00:00Z",
            "user_country_id": null,
            "user_city_id": null,
            "user_gmt_id": null,
            "user_gmt_time": "5",
            "user_sex": null,
            "user_photo_ID": null,
            "user_interesting_topics": null,
            "user_description": null,
            "user_organization_name": null,
            "user_organization_addres": null,
            "user_organization_itn": null,
            "user_work_position": null,
            "user_passport": null,
            "user_snils": null,
            "user_itn": null,
            "user_addres_registration": null,
            "user_employment_docs": null,
            "user_confirm_policy": false,
            "id_employment_type": null,
            "deleted_at": "0001-01-01T00:00:00",
            "balance": 0,
            "role": null,
            "employmentType": null,
            "file": null,
            "is_banned": false,
            "jwts": null
        },
        {
            "id": 2,
            "mail": "tnv24@pcruss.ru",
            "password": "204660d39460da10199ba96317effa8e58a044857a07c6b24811eefc1ba64366",
            "salt": "BCmPmxe1CWOlEVcVNAmKqOf8zXC2zKf/UEDdF9bHizg=",
            "current_folder_size": 0,
            "confirmed": true,
            "role_id": 2,
            "user_name": "Николай",
            "user_surname": "Толстоухов",
            "user_patronymic": "Владимирович",
            "user_phone_number": null,
            "user_nikname": null,
            "user_birthday": "2000-10-09T15:00:00Z",
            "user_country_id": null,
            "user_city_id": null,
            "user_gmt_id": null,
            "user_gmt_time": "5",
            "user_sex": null,
            "user_photo_ID": null,
            "user_interesting_topics": null,
            "user_description": null,
            "user_organization_name": null,
            "user_organization_addres": null,
            "user_organization_itn": null,
            "user_work_position": null,
            "user_passport": null,
            "user_snils": null,
            "user_itn": null,
            "user_addres_registration": null,
            "user_employment_docs": null,
            "user_confirm_policy": false,
            "id_employment_type": null,
            "deleted_at": "0001-01-01T00:00:00",
            "balance": 0,
            "role": null,
            "employmentType": null,
            "file": null,
            "is_banned": false,
            "jwts": null
        },
        {
            "id": 3,
            "mail": "zas09@pcruss.ru",
            "password": "e400887282be39615e3eb5f6057b41db1327bfeeec5d04d8d54ea1d04de52ec9",
            "salt": "WdOsTd/qKeL6R5Jhdz/G1ZLoKSdlgsK4esSJ0/eVgNE=",
            "current_folder_size": 0,
            "confirmed": true,
            "role_id": 2,
            "user_name": "Андрей",
            "user_surname": "Зырянов",
            "user_patronymic": "Станиславович",
            "user_phone_number": null,
            "user_nikname": null,
            "user_birthday": "2024-05-01T16:00:00Z",
            "user_country_id": null,
            "user_city_id": null,
            "user_gmt_id": null,
            "user_gmt_time": "6",
            "user_sex": null,
            "user_photo_ID": null,
            "user_interesting_topics": null,
            "user_description": null,
            "user_organization_name": null,
            "user_organization_addres": null,
            "user_organization_itn": null,
            "user_work_position": null,
            "user_passport": null,
            "user_snils": null,
            "user_itn": null,
            "user_addres_registration": null,
            "user_employment_docs": null,
            "user_confirm_policy": false,
            "id_employment_type": null,
            "deleted_at": "0001-01-01T00:00:00",
            "balance": 0,
            "role": null,
            "employmentType": null,
            "file": null,
            "is_banned": false,
            "jwts": null
        },
        {
            "id": 4,
            "mail": "oleg@mail.ru",
            "password": "e400887282be39615e3eb5f6057b41db1327bfeeec5d04d8d54ea1d04de52ec9",
            "salt": "WdOsTd/qKeL6R5Jhdz/G1ZLoKSdlgsK4esSJ0/eVgNE=",
            "current_folder_size": 0,
            "confirmed": true,
            "role_id": 2,
            "user_name": "Олег",
            "user_surname": "Смирнов",
            "user_patronymic": "Станиславович",
            "user_phone_number": null,
            "user_nikname": null,
            "user_birthday": "2024-05-01T16:00:00Z",
            "user_country_id": null,
            "user_city_id": null,
            "user_gmt_id": null,
            "user_gmt_time": "6",
            "user_sex": null,
            "user_photo_ID": null,
            "user_interesting_topics": null,
            "user_description": null,
            "user_organization_name": null,
            "user_organization_addres": null,
            "user_organization_itn": null,
            "user_work_position": null,
            "user_passport": null,
            "user_snils": null,
            "user_itn": null,
            "user_addres_registration": null,
            "user_employment_docs": null,
            "user_confirm_policy": false,
            "id_employment_type": null,
            "deleted_at": "0001-01-01T00:00:00",
            "balance": 0,
            "role": null,
            "employmentType": null,
            "file": null,
            "is_banned": true,
            "jwts": null
        }
    ]
}