import styled from "styled-components";

export const CardWrap = styled.div`
    max-width: 100%;
    height: min-constent;
    /* border: 1px solid #bdbdbd; */
    padding: 20px;
    border-radius: 6px;
    background-color: #fafafa;
    box-shadow: 1px 1px 14px #00000030;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & span {
        white-space: pre-wrap;
    }
`