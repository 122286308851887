import styled from "styled-components";

const InputSearch = styled.input`
    border: 1px solid rgba(201,201,204,.48);
    background:#EDEDED;
    border-radius: 10px;
    padding: 10px 12px;
    outline: none;
    width: 100%;
`

export default InputSearch;