import React, { useEffect, useState } from 'react';
import { Form, FormWrapper, FormField } from './styled';
import { PageWrap, InputDefault } from 'ui/styled';
import { GreenButton } from 'ui/components/Buttons';
import userStore from 'store/userStore'
import { useNavigate } from 'react-router-dom';
import usePassEncrypt from 'hooks/usePassEncrypt';
import { observer } from 'mobx-react';

const LoginPage = observer(() => {

    const [data, setData] = useState({ mail: '', password: '' })
    const passEncrypt = usePassEncrypt()

    const navigate = useNavigate()
    const goHome = () => navigate('/')

    useEffect(() => {
        if (userStore.auth) {
            goHome()
        }
    }, [userStore.auth])

    const logIn = async (e) => {
        e.preventDefault()

        await userStore.logIn({
            mail: data.mail,
            password: passEncrypt(data.password),
            device_type_id: '123',
            platform: '123',
            geo: '456',
            system: '567'
        })
        goHome()
    }

    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PageWrap style={{ alignItems: 'center' }}>
                <FormWrapper>
                    <h3>Вход</h3>
                    <Form data-testid="login-form" onSubmit={(e) => logIn(e)}>
                        <FormField>
                            <label htmlFor='mail'>Почта</label>
                            <InputDefault data-testid="email-input" id='mail' type={'email'} name={'mail'} value={data.mail} onChange={(e) => setData(prevState => { return { ...prevState, mail: e.target.value } })} />
                        </FormField>
                        <FormField>
                            <label htmlFor='password'>Пароль</label>
                            <InputDefault data-testid="password-input" id='password' type={'password'} name={'password'} value={data.password} onChange={(e) => setData(prevState => { return { ...prevState, password: e.target.value } })} />
                        </FormField>
                        <GreenButton type={'submit'}>
                            <h3>Войти</h3>
                        </GreenButton>
                    </Form>
                </FormWrapper>
            </PageWrap>
        </div>
    );
});

export default LoginPage;