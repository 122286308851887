import styled from "styled-components";

const AddUserButton = styled.button`
    flex:50%;
    background:#E8F1FF;
    color:#163C85;
    padding: 10px 16px;
    border-radius:10px;
`

export default AddUserButton