import styled from "styled-components";

const ButtonConform = styled.button`
    background-color: #FFE1E1;
    color:#FF5F5F;
    flex:50%;
    padding: 5px 20px;
    border-radius:10px;
`

export default ButtonConform;