import styled from "styled-components";

const Figure = styled.figure`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.4em;
    overflow: hidden;
    border: ${({ $border }) => $border ? '1px solid #e8e8eb' : 'none'};
    margin-bottom: ${({ $caption }) => $caption ? '5px' : null};
    background: ${({ $background }) => $background ? '#cdd1e0' : null};
    padding: ${({ $background }) => $background ? '15px' : null};

    & img {
        border-radius: 3px;
        overflow: hidden;
        max-width: ${({ $background }) => $background ? '60%' : null};
        margin: ${({ $background }) => $background ? '0 auto' : null};
    }

    & figcaption {
        bottom: ${({ $background }) => $background ? '0px' : null};
    }
`

export default Figure;