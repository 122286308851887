import styled from "styled-components";

const SubjectItem = styled.li`
  padding: 16px 100px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  color: #163C85;

  &:hover {
    background-color: #f1f1f1;
  }

  &.selected {
    background-color: #E8F1FF; 
    color: #163C85;
  }
`;

export default SubjectItem;