import styled from "styled-components";

const Title = styled.h4`
    font-size: 20px;
    font-weight: 400;
    color: #1b399d;
    margin: 0;
    padding: 0;
`

export default Title;