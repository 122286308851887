import styled from "styled-components"

const InputDate = styled.input`
    border: 1px solid rgba(201,201,204,.48);
    background:#EDEDED;
    border-radius: 10px;
    padding: 10px 12px;
    outline: none;
`

export default InputDate