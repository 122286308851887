import styled from "styled-components";

const LayoutWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: ${({row}) => row ? 'row' : 'column'};
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`

export default LayoutWrap;