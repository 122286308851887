import styled from "styled-components"
import { CoursesListWrap } from ".";

const CoursesListContainer = styled(CoursesListWrap)`
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px; 
    max-height: 400px; 
    overflow-y: auto; 
`;

export default CoursesListContainer