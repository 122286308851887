import styled from "styled-components";

const ImgWrap = styled.div`
    width: 100%;
    /* max-width: 80%; */
    display: ${({ $stretched }) => $stretched ? 'block' : 'flex'};
    margin: 0 auto;
    max-width: ${({ $stretched }) => $stretched ? '100%' : '80%'};
`

export default ImgWrap;