import styled from "styled-components";

export const Button = styled.button`
    padding: 8px 10px;
    border-radius: 4px;
    color: #fff;
    background: ${({ $background, disabled }) => (disabled ? '#ccc' : $background)};
    font-weight: 500;
    font-size: 16px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    &:hover {
        background: ${({ $hover, disabled }) => (disabled ? '#ccc' : $hover)};
    }
`;
