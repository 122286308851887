import React from 'react';
import { Outlet } from 'react-router-dom';
import { ContentWrapper, LayoutSection, LayoutWrap } from './styled';
import { MainSidebar } from 'ui/components/Sidebars';
import { Header } from 'ui/components/Header';

const Layout = () => {
    return (
        <LayoutWrap row={'true'}>
            {/* <Header /> */}
            <LayoutSection>
                <MainSidebar />
                <ContentWrapper>
                    <Outlet />
                </ContentWrapper>
            </LayoutSection>
        </LayoutWrap>
    );
};

export default Layout;