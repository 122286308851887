import React from 'react';
import { Button } from 'ui/styled';

const GreenButton = ({ background, hover, children, disabled, ...props }) => {
    return (
        <Button
            $background={background ? background : '#3dce61'}
            disabled={disabled}
            $hover={hover ? hover : '#38e062'}
            {...props}
        >
            {children}
        </Button >
    );
};

export default GreenButton;