import React from 'react';
import { Button, Indicator } from './styled';
import { useLocation } from 'react-router-dom';

const NavLink = ({ to, children, ...props }) => {

    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <Button to={to} {...props} $isActive={isActive}>
            {
                isActive ? <Indicator /> : null
            }
            {children}
        </Button>
    );
};

export default NavLink;
