import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FirstWrap, Title, SubjectItem, SubjectWrap, SubjectTitle, Content } from './styled';
import { InputDefault, PageWrap } from 'ui/styled';
import subjectsStore from 'store/subjectsStore';
import { GreenButton, RedButton } from 'ui/components/Buttons';
import { observer } from 'mobx-react';
import Form from 'ui/components/Form/Form';

import SubjectItems from './components/SubjectItems';
import { Pagination } from 'ui/components/Pagination';

const itemsPerPage = 10;

const Home = observer(() => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [editing, setEditing] = useState(null);
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [editingValue, setEditingValue] = useState('');
    const [addingValue, setAddingValue] = useState('');
    const [subject, setSubject] = useState({});

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                await subjectsStore.loadSubjects();
                setData(subjectsStore.subjects);
            } catch (e) {
                console.log(e);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    const handleEditSubject = useCallback((subject) => {
        setEditing(subject.id);
        setEditingValue(subject.subject);
    }, []);

    const handleSaveEdit = useCallback(async (id) => {
        await subjectsStore.updateSubject(id, editingValue);
        setEditing(null);
        setData(subjectsStore.subjects);
    }, [editingValue]);

    const handleRemoveSubject = useCallback(async (id) => {
        await subjectsStore.removeSubject(id);
        setData(subjectsStore.subjects);
    }, []);

    const handleAddSubject = useCallback(async () => {
        await subjectsStore.addSubject(addingValue);
        setAddingValue('');
        setData(subjectsStore.subjects);
    }, [addingValue]);

    const indexOfLastItem = currentIndex * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = useMemo(() => {
        return data.slice(indexOfFirstItem, indexOfLastItem)
    }, [data, indexOfFirstItem, indexOfLastItem]);

    if (error) {
        return <div>Произошла ошибка при загрузке</div>;
    }

    return (
        <PageWrap>
            <FirstWrap>
                <Title>Список направлений</Title>
                <Content>
                    <div>
                        <SubjectItems
                            items={currentItems}
                            editingValue={editingValue}
                            loading={loading}
                            editing={editing}
                            setEditingValue={setEditingValue}
                            handleSaveEdit={handleSaveEdit}
                            handleRemoveSubject={handleRemoveSubject}
                            handleEditSubject={handleEditSubject}
                        />
                        <Pagination
                            items={data}
                            setIndex={setCurrentIndex}
                            itemsPerPage={itemsPerPage}
                        />
                    </div>
                    <Form onEvent={handleAddSubject} addingValue={addingValue} setAddingValue={setAddingValue} />
                </Content>
            </FirstWrap>
        </PageWrap>
    );
});

export default Home;
