import { useState } from "react";

const useEditGroup = (initialValue) => {
    const [isEditing, setIsEditing] = useState(false);
    const [groupName, setGroupName] = useState(initialValue);

    const startEditing = () => setIsEditing(true);
    const stopEditing = () => setIsEditing(false);
    const saveGroup = (newName) => setGroupName(newName);

    return { isEditing, groupName, startEditing, stopEditing, saveGroup };
};


export default useEditGroup;