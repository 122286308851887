import React from 'react';
import { formatDate } from 'hooks';

const ModerHistory = ({ list }) => {
    return (
        < div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            {
                list.length ?
                    list.map(histor =>
                        <div key={histor.id} style={{ display: 'flex', flexDirection: 'column', gap: '5px', borderBottom: '1px solid #e6e6e6', padding: '10px 0' }}>
                            <p>id: {histor.id}</p>
                            <p>courseId: {histor.courseId}</p>
                            <p>userId: {histor.userId}</p>
                            <p>status: {histor.status}</p>
                            <p>date: {formatDate(histor.date)}</p>
                            <p>comment: {histor.comment}</p>
                            <p>type: {histor.type}</p>
                        </div>
                    )
                    : <p style={{ lineHeight: 'normal' }}>История отсутствует</p>
            }
        </div >
    );
};

export default ModerHistory;