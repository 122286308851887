import styled from "styled-components";

const LogItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 3fr 3fr;
    padding: 8px;
    /* margin-bottom: 5px; */
    background-color: #660000; /* цвет для логов */
    border: 1px solid #990000; /* рамка */
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  & div {
    padding: 0 5px;
    text-align: left;
    word-break: break-all;
  }

  &:hover {
    background-color: #ff0000; /* Ярко-красный при наведении */
    color: #fff;
    transition: background-color 0.3s ease;
  }
`

export default LogItem;