import styled from "styled-components";

const AccordionWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1200;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export default AccordionWrapper;