import React, { useRef, useState } from 'react';
import { ArrowIcon, GroupContentWrapper, InputEditing } from ".";
import { BlockWrap, GroupWrap, InputEditWrap, ListGroupWrap, PositionElement } from "../styled";
import SettingsIcon from "./SettingsIcon";
import ConfirmationModal from 'ui/Popups/ConfirmationPopup';
import { AdvancedSettingsPopup } from 'ui/Popups/AdvancedSettingsPopup';
import { useEditGroup, useModal } from '../hooks';

const ListGroups = ({ group, onGroupSelect }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { isEditing, groupName, startEditing, stopEditing, saveGroup } = useEditGroup(group.name);
    const { isVisible: isSettingsOpen, openModal: openSettings, closeModal: closeSettings } = useModal();
    const { isVisible: isConfirmModalOpen, openModal: openConfirmModal, closeModal: closeConfirmModal } = useModal();
    const wrapperRef = useRef(null);
    const id = group.id;

    const toggleExpand = () => {
        if (isEditing) {
            return false;
        }

        setIsExpanded(!isExpanded);
    };

    const handleSelect = () => {
        onGroupSelect(group);
    };

    return (
        <ListGroupWrap ref={wrapperRef} onClick={handleSelect}>
            <GroupWrap hasShadow>
                <BlockWrap onClick={toggleExpand} style={{ cursor: 'pointer' }}>
                    <ArrowIcon rotated={isExpanded} />
                    <InputEditWrap>
                        <InputEditing
                            groupName={groupName}
                            isEditing={isEditing}
                            startEditing={startEditing}
                            stopEditing={stopEditing}
                            saveGroup={saveGroup} />
                    </InputEditWrap>
                </BlockWrap>
                <BlockWrap>
                    <span>Участников:</span>
                    <span> {group?.users?.length}</span>
                </BlockWrap>

                <PositionElement>
                    <SettingsIcon handleClick={openSettings} />
                    <AdvancedSettingsPopup
                        group={group}
                        id={id}
                        showModal={isSettingsOpen}
                        handleCloseModal={closeSettings}
                        openConfirmModal={openConfirmModal}
                    />
                </PositionElement>
            </GroupWrap>
            <ConfirmationModal
                id={id}
                show={isConfirmModalOpen}
                onClose={closeConfirmModal}
                text="Точно хотите удалить группу?"
            />
            {
                isExpanded && (
                    <GroupContentWrapper group={group} />
                )
            }
        </ListGroupWrap >
    );
};

export default ListGroups;
