import styled from "styled-components"

const ButtonSuccess = styled.button`
    color:#FFFFFF;
    background:#FF7676;
    font-size:18px;
    padding:12px 30px;
    border-radius:10px;
    cursor:pointer;
`

export default ButtonSuccess;