import { observer } from "mobx-react";
import { UserCard } from ".";

const UsersList = observer(({ users, loading }) => {
    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                Загрузка...
            </div>
        );
    }

    if (users.length === 0) return <p style={{ textAlign: 'center' }}>По вашему запросу ничего не найдено</p>;

    return (
        <>
            {users.map(user => (
                <UserCard el={user} key={user.id} />
            ))}
        </>
    );
});

export default UsersList;
