import styled from "styled-components";

const SubjectWrap = styled.div`
    display: flex;
    flex-direction: column;
    ${'' /* flex: 0 0 70%; */}
    ${'' /* align-items: center; */}
    gap: 10px;
`;

export default SubjectWrap