import React from 'react';
import { InputDefault } from "ui/styled";
import { SubjectItem, SubjectTitle, SubjectWrap } from "../styled";
import { GreenButton, RedButton } from "ui/components/Buttons";
import subjectsStore from 'store/subjectsStore';
import { observer } from 'mobx-react';

const SubjectItems = observer(({ items, loading, editing, editingValue, setEditingValue, handleSaveEdit, handleRemoveSubject, handleEditSubject }) => {
    return (
        <SubjectWrap>
            {loading ? <div>Загрузка...</div> :
                items.length > 0 ? items.map(subject => (
                    <SubjectItem key={subject.id}>
                        {editing === subject.id ? (
                            <InputDefault
                                value={editingValue}
                                onChange={(e) => setEditingValue(e.target.value)}
                                onBlur={() => handleSaveEdit(subject.id)}
                            />
                        ) : (
                            <SubjectTitle>{subject.subject}</SubjectTitle>
                        )}
                        <GreenButton onClick={() => editing === subject.id ? handleSaveEdit(subject.id, subject.subject) : handleEditSubject(subject)}>{editing === subject.id ? 'Сохранить' : 'Редактировать'}</GreenButton>
                        <RedButton onClick={() => handleRemoveSubject(subject.id)}>Удалить</RedButton>
                    </SubjectItem>
                )) : <div>Нет данных</div>
            }
        </SubjectWrap>
    )
});

export default SubjectItems;
