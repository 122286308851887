import React, { useEffect, useMemo, useState } from 'react';
import { PageWrap, PageHeader } from 'ui/styled';
import { Pagination } from 'ui/components/Pagination';
import { exportTransactionsToXML, getSuspiciousTransactions } from './api';
import FormDate from './components/FormDate';
import { TransactionsList } from './components';


const itemsPerPage = 10;

const SpiciousListPage = () => {
    const [transactions, setTransactions] = useState([]);
    const [filter, setFilter] = useState({ value: 'all', label: 'Все транзакции' });
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [date, setDate] = useState({ from: null, to: null });

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            setError(null);
            try {
                const result = await getSuspiciousTransactions({
                    createdFrom: date.from || null,
                    createdTo: date.to || null,
                });
                setTransactions(result.data.data);
            } catch (error) {
                console.log('Ошибка');
                setError('Произошла ошибка при загрузке транзакций');
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, [date]);

    const currentItems = useMemo(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        return transactions.slice(indexOfFirstItem, indexOfLastItem);
    }, [transactions, currentPage]);

    const handleExport = async () => {
        const ids = transactions.map((transaction) => transaction.id);
        try {
            const result = await exportTransactionsToXML(ids);
            const file = new Blob([result.data], { type: 'application/xml' });
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'suspicious.xml';
            a.click();
        } catch (error) {
            console.error('Ошибка при экспорте в XML:', error);
        }
    };

    if (error) return <p style={{ textAlign: 'center' }}>{error}</p>;

    return (
        <PageWrap>
            <FormDate date={date} setDate={setDate} handleExport={handleExport} />

            <TransactionsList transactions={currentItems} loading={loading} />

            <Pagination
                items={transactions}
                setIndex={setCurrentPage}
                itemsPerPage={itemsPerPage}
            />
        </PageWrap>
    );
};

export default SpiciousListPage;
