import styled from "styled-components";

const InputDate = styled.input`
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    padding: 5px 20px;
    width: 100%;
    ${'' /* margin-right: 10px; */}
`

export default InputDate