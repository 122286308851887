import styled from "styled-components"

export const FormField = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    & label{
        font-size: 16px;
        font-weight: 600;
    }
`