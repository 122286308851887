import styled from "styled-components";

const ModalWrap = styled.div`
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index:1300;
    padding: 20px;
    border-radius: 20px;
    border:1px solid rgba(0,0,0,0.2);
`

export default ModalWrap;