import React, { useState } from 'react';
import { Card, Form, NotElements } from ".";
import { CoursesListBlock, CoursesListWrap, PaginationWrap } from "../styled";
import { Pagination } from 'ui/components/Pagination';
import groupsStore from 'store/groupsStore';
import { observer } from 'mobx-react';

const CoursesList = observer(({ group }) => {
    const [currentPage, setCurrentPage] = useState(groupsStore.selectedGroup.coursePageInfo.currentPage);

    return (
        <CoursesListBlock>
            <Form type={"courses"} text="Добавить курс" title="Курсы" />

            <CoursesListWrap>
                {
                    group?.courses.length !== 0
                        ? group.courses.map((courseGroup) => (
                            <Card
                                key={courseGroup.courseId}
                                item={courseGroup}
                                type="course"
                                group={group}
                            />
                        ))
                        : <NotElements text={"На данный момент в группе нет курсов"} />
                }
            </CoursesListWrap>
            {
                group?.courses.length !== 0 && (
                    <PaginationWrap>
                        <Pagination
                            currentPage={currentPage}
                            setIndex={setCurrentPage}
                            totalPages={groupsStore.selectedGroup.coursePageInfo.totalPages}
                        />
                    </PaginationWrap>
                )
            }
        </CoursesListBlock >
    );
});

export default CoursesList;
