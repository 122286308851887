import styled from "styled-components"

const ButtonsWrap = styled.div`
    position: absolute;
    top: 2px;
    right: 5px;
    display: flex;
    gap: 10px; 
`

export default ButtonsWrap;