import React from 'react';
import OverlayPopup from '../OverlayPopup';
import { IoClose } from 'react-icons/io5'
import {
    Header,
    MainPopupWrapper
} from './styled';

const MainPopup = ({
    isOpened,
    onClose,
    title,
    children,
    width,
    showHeader = true,
}) => {

    return (
        <OverlayPopup isOpened={isOpened} onClose={onClose}>
            <MainPopupWrapper width={width}>
                {
                    showHeader &&
                    <Header>
                        <span>{title}</span>
                        <button type='button' onClick={onClose}>
                            <IoClose size={'1.6em'} />
                        </button>
                    </Header>
                }
                {children}
            </MainPopupWrapper>
        </OverlayPopup>

    );
};

export default MainPopup;