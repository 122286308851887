import { api } from 'api';
import React, { useState } from 'react';
import { PageWrap } from 'ui/styled';
import { FileButton, FileButtonChange, FileInputWrapper, FileWrap, InputFile, Table, Wrapper } from './styled';
import { FileUploadButton, TableRow } from './components';

const DocsPage = () => {
    const [files, setFiles] = useState({
        installation_instructions: null,
        user_guide: null,
        privacy_policy: null,
        functional_characteristics: null,
        user_agreement: null,
    })

    async function uploadDocument(File, urlPath) {
        const formData = new FormData();
        formData.append('file', File);
        const res = await api.post(
            urlPath,
            formData,
        )
        return res.data.id
    }

    const handleFileChange = (event, fileType) => {
        let file = event.target.files[0];
        if (!file) return;
        setFiles((prevFiles) => ({ ...prevFiles, [fileType]: file }));
    };

    const sendApiFile = async (name) => {
        await uploadDocument(files[name], `files/uploadfile/${name}`)
            .then(() => {
            })
            .catch(error => console.log(error))
    }

    return (
        <PageWrap>
            <Table border="1">
                <thead>
                    <tr>
                        <th>Название документа</th>
                        <th>Файл документа</th>
                    </tr>
                </thead>
                <tbody>
                    <TableRow
                        label="Инструкция по установке"
                        file={files.installation_instructions}
                        fileType="installation_instructions"
                        handleFileChange={handleFileChange}
                        sendApiFile={sendApiFile}
                    />
                    <TableRow
                        label="Руководство пользователя, установки и эксплуатации"
                        file={files.user_guide}
                        fileType="user_guide"
                        handleFileChange={handleFileChange}
                        sendApiFile={sendApiFile}
                    />
                    <TableRow
                        label="Политика обработки персональных данных"
                        file={files.privacy_policy}
                        fileType="privacy_policy"
                        handleFileChange={handleFileChange}
                        sendApiFile={sendApiFile}
                    />
                    <TableRow
                        label="Описание функциональных характеристик"
                        file={files.functional_characteristics}
                        fileType="functional_characteristics"
                        handleFileChange={handleFileChange}
                        sendApiFile={sendApiFile}
                    />
                    <TableRow
                        label="Пользовательское соглашение"
                        file={files.user_agreement}
                        fileType="user_agreement"
                        handleFileChange={handleFileChange}
                        sendApiFile={sendApiFile}
                    />
                </tbody>
            </Table>
        </PageWrap>
    );
};

export default DocsPage;