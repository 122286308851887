import styled from 'styled-components'

const Title = styled.h1`
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    text-align: center;

    &:not(:last-child) {
    margin-bottom: 40px;
  }
`

export default Title