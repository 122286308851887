import { api } from "api";
import { makeAutoObservable } from "mobx";
import { addNewGroup, deleteGroup, loadGroups } from "routes/pages/GroupsPage/api";
import { getCourseNotGroup } from "routes/pages/ModerPage/api";
import { usersNotGroup } from "routes/pages/UsersPage/api";
import { addNewCourse, addNewUser, copyLink, coursesNotGroupFilters, deleteCourse, deleteLink, deleteUser, updateGroup } from './../routes/pages/GroupsPage/api/actions';

class Groups {
    pageNumber = 1;
    pageSize = 10;
    groups = [];
    totalPages;
    users = [];
    courses = [];
    currentPageCourse;
    totalPagesCourse;
    totalCourses;
    currentPageUser;
    totalPagesUser;
    totalUsers;
    link = '';
    options = { isaccess: false, visible: false, name: '', numberOfParticipants: 0 };
    selectedGroup = null;

    constructor() {
        makeAutoObservable(this)
    }

    async loadGroups(pageNumber, pageSize) {
        const response = await loadGroups(pageNumber, pageSize);

        this.setGroups(response.groups);
        this.totalPages = response.totalPages;
        return response;
    }

    async refreshSelectedGroup() {
        if (this.selectedGroup) {
            const groupId = this.selectedGroup.id;
            const response = await loadGroups(1, 10); // Загружаем все группы
            const updatedGroup = response.groups.find(group => group.id === groupId);
            if (updatedGroup) {
                this.setSelectedGroup(updatedGroup);
            } else {
                console.log(`Группа с ID ${groupId} не найдена при обновлении.`);
            }
        } else {
            console.log("Нет выбранной группы для обновления.");
        }
    }



    async addNewGroup(newGroup) {
        const response = await addNewGroup(newGroup);

        if (response.status === 200) {
            this.loadGroups(1, 10);
        }
    }

    async deleteGroup(id) {
        const response = await deleteGroup(id);

        if (response.status === 200) {
            this.loadGroups(1, 10);
        }
    }

    async copyLink(id) {
        const response = await copyLink(id);

        this.setLink(response);
    }

    async deleteLink(groupId) {
        await deleteLink(groupId)
        this.setLink('');
    }

    async addNewCourse(groupId, courseId) {
        const response = await addNewCourse(groupId, courseId);

        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
        }
    }

    async deleteCourse(groupId, courseId) {
        const response = await deleteCourse(groupId, courseId);

        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
        }
    }

    async addNewUser(groupId, userId) {
        const response = await addNewUser(groupId, userId);

        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
        }
    }

    async deleteUser(groupId, userId) {
        const response = await deleteUser(groupId, userId);

        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
        }
    }

    async updateGroup(id, updatedData) {
        await updateGroup(id, updatedData);
    }

    setGroups(data) {
        this.groups = data;
    }

    setCurrentPageCourse(page) {
        this.currentPageCourse = page;
    }

    setCurrentPageUser(page) {
        this.currentPageUser = page;
    }

    setLink(link) {
        this.link = link;
    }

    setSelectedGroup(group) {
        this.selectedGroup = { ...group };
    }

    async loadCourseNotGroup(idgroup, currentPage = 1) {
        let { data } = await getCourseNotGroup(idgroup, currentPage);
        this.setCourses(data.allCourses);
        this.totalCourses = data.totalPages;
    }

    async coursesNotGroupFilters(id, arr_filters) {
        let { data } = await coursesNotGroupFilters(id, arr_filters);
        this.setCourses(data);
    }
    async loadUsersNotGroup(groupId, currentPage = 1) {
        const response = await usersNotGroup(groupId, currentPage);
        this.setUsers(response.data.user);
        this.totalUsers = response.data.totalPages;
    }

    setUsers(data) {
        this.users = data;
    }

    setCourses(data) {
        this.courses = data;
    }
}

export default new Groups();