import { formatDate } from 'hooks';
import React from 'react';
import { CardWrap } from 'ui/styled';

const elem = ({ elem, key }) => {

    return (
        <CardWrap key={key}>
            <h2>Информация о транзакции</h2>
            <p>Id подозрительной транзакции: <span>{elem.id}</span></p>
            <p>Id транзакции: <span>{elem.transactionId}</span></p>
            <p>Дата создания: <span>{formatDate(elem.createdAt)}</span></p>
            <p><b>Причина</b>:
                <span>{elem.reason}</span>
            </p>
            <h3>Информация о транзакции</h3>
            <p>Id транзакции: <span>{elem.transaction.id}</span></p>
            <p>Id курса: <span>{elem.transaction.coursID}</span></p>
            <p>Статус: <span>{elem.transaction.status}</span></p>
            <p>Сумма: <span>{elem.transaction.sum}</span></p>
            <p>Id пользователя: <span>{elem.transaction.userId}</span></p>
            <p>Id карты: <span>{elem.transaction.cardId}</span></p>
            <p>Дата создания: <span>{formatDate(elem.transaction.createdAt)}</span></p>
            <p>Дата обновления: <span>{formatDate(elem.transaction.updateAt)}</span></p>
        </CardWrap>
    );
};

export default elem;