import styled from "styled-components";

const MainPopupWrapper = styled.div`
    background-color: #f8f8f8;
    width: ${({ width }) => width ? width : '440px'};
    max-height: 700px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    z-index: 1100;
    position:relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export default MainPopupWrapper;