import { SubjectFilterPopup } from "ui/Popups/SubjectFilterPopup";
import { ArrowIcon, PlusIcon } from ".";
import { AddGroup, FilterButton, RollButton, SubjectsWrap } from "../styled";
import AddGroupWrap from "../styled/AddGroupWrap";
import FilterContent from './../styled/FilterContent';
import FilterContainer from './../styled/FilterContainer';
import FilterText from "../styled/FilterText";
import { UserPopup } from "ui/Popups/UserPopup";
import { useModal } from "../hooks";

const Filter = ({
    id,
    width,
    height,
    filtered,
    buttonRef,
    toggleExpand,
}) => {
    const {
        isVisible: isSubjectModalVisible,
        openModal: openSubjectModal,
        closeModal: closeSubjectModal,
    } = useModal();

    const {
        isVisible: isUserModalVisible,
        openModal: openUserModal,
        closeModal: closeUserModal,
    } = useModal();

    return (
        <FilterContainer>
            <FilterContent>
                {filtered ? (
                    <>
                        <FilterText>Список фильтров:</FilterText>
                        <SubjectsWrap>
                            <div onClick={openSubjectModal}>
                                <FilterButton ref={buttonRef}>
                                    предмет
                                </FilterButton>
                                <ArrowIcon flip={true} rotated={isSubjectModalVisible} width={width} height={height} />
                            </div>
                            <SubjectFilterPopup
                                id={id}
                                handleCloseModal={closeSubjectModal}
                                show={isSubjectModalVisible}
                            />
                        </SubjectsWrap>
                    </>
                ) : (
                    <AddGroupWrap>
                        <PlusIcon />
                        <AddGroup onClick={openUserModal}>Добавить нового пользователя</AddGroup>
                    </AddGroupWrap>
                )}
                {isUserModalVisible && (
                    <UserPopup show={isUserModalVisible} onClose={closeUserModal} />
                )}
            </FilterContent>
            <RollButton onClick={toggleExpand}>Свернуть</RollButton>
        </FilterContainer>
    );
};

export default Filter;
