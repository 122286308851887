import { ButtonClose, ButtonSuccess, ModalContentStyle, ModalStyle, Title } from "../styled";
import ReactDOM from "react-dom";

const ConfirmationPopup = ({ onClose }) => {
    return ReactDOM.createPortal(
        <ModalStyle>
            <ModalContentStyle>
                <Title>Ссылка удалена!</Title>
                <ButtonClose style={{ background: '#E8F1FF', textAlign: 'center' }} onClick={onClose}>OK</ButtonClose>
            </ModalContentStyle>
        </ModalStyle>,
        document.body
    );
};

export default ConfirmationPopup;