import styled from "styled-components";

const Figcaption = styled.figcaption`
    /* position: absolute; */
    text-align: center;
    bottom: 1px;
    padding: 5px 10px;
    font-size: 12px;
    background-color: rgb(255 255 255);
    color: white;
    border-radius: 2px;
    cursor: default;
    color: #333;
`

export default Figcaption;