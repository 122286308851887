import styled from "styled-components";

const Popup = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  top: -280px;
  left: 60px;
`;

export default Popup;