import { useEffect, useState } from "react";
import groupsStore from "store/groupsStore";

const useFetchGroups = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const pageNumber = groupsStore.pageNumber;
    const pageSize = groupsStore.pageSize;

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                setLoading(true);
                setError(null);

                await groupsStore.loadGroups(pageNumber, pageSize);
            } catch (error) {
                setError('Ошибка при загрузке групп');
            } finally {
                setLoading(false);
            }
        };

        fetchGroups();

    }, [pageNumber, pageSize]);

    return { loading, error };
};

export default useFetchGroups;