import styled from "styled-components"

const CoursesListWrap = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    flex-grow: 1;
`

export default CoursesListWrap