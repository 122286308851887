import styled from "styled-components";

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &>span {
        font-size: 20px;
        font-weight: 600;
    }

    &>button {
        width: 42px;
        height: 42px;
        border-radius: 14px;
        padding: 4px;
        background-color: rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #707070;
    }

    &>button:hover {
        background-color: rgba(0, 0, 0, 0.25);
    }
`

export default Header;