import styled from "styled-components";
import { NavLink } from "ui/styled";

const Button = styled(NavLink)`
    margin-top: 20px;
    background-color: #ce5353;
    color: #fff;
    &&:hover {
        background-color: #e25252;
    }
`
export default Button