import { api } from "api";
import config from "config";
import mockData from "../mock/mockData";

export const getHistory = (courseId) => {
    return api.get(`/courses/ModerationHistory/${courseId}`);
}

//-----
//true - курсы на модерации
//false - все курсы
export const getCoursesCount = () => {
    return api.get(`/courses/CoursesCount/2/false`)
}
//------

export const getCourses = (page = 1, pageSize = 10, filter = false, status = 0) => {
    if (config.useMocks) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({ data: mockData })
            }, 1000);
        })
    }
    if (filter) {
        return api.get(`/courses/FilterCourses`, {
            params: {
                moderationstatus: status,
                page: page,
                pageSize: pageSize,
            }
        })
    }

    return api.get(`/courses/all`, {
        params: {
            page: page,
            pageSize: pageSize
        }
    })

}

export const getCourseNotGroup = (id) => {
    return api.get(`groups/CoursesNotGroup`, {
        params: {
            groupId: id
        }
    })
}

export const changeStatusCourse = (course) => {
    return api.post(`${process.env.REACT_APP_API}/courses/ModerStateCourses`, course)
}

export const changeStatusLesson = (course) => {
    return api.post(`${process.env.REACT_APP_API}/lessons/ModerStateLessons`, course)
}

export const patchCourse = (id, data) => {
    return api.patch(`/courses/${id}`, data)
}