import styled from "styled-components"

const CoursesListBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    flex:0 1 50%;
    height: 100%;
    align-self:flex-start;
    z-index: 1;
`

export default CoursesListBlock