// components/TransactionsList.js
import React from 'react';
import CardSpiciousTransaction from './CardSpiciousTransaction';

const TransactionsList = ({ transactions, loading }) => {
    if (loading) {
        return <p style={{ textAlign: 'center', padding: '20px' }}>Загрузка...</p>;
    }

    if (transactions.length === 0) {
        return <p style={{ textAlign: 'center', padding: '20px' }}>Нет подозрительных транзакций для отображения</p>;
    }

    return (
        <div>
            {transactions.map((transaction) => (
                <CardSpiciousTransaction elem={transaction} key={transaction.transactionId} />
            ))}
        </div>
    );
};

export default TransactionsList;
