import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'layouts';
import {
  LoginPage,
  ContentLessonPage,
  Home,
  ModerPage,
  UsersPage,
  DocsPage,
  LogsPage,
  SpiciousListPage,
  GroupsPage
} from './routes/pages';
import HocPrivateRoute from './hoc/HocPrivateRoute';
import { useEffect } from 'react';
import userStore from './store/userStore'

const App = () => {

  useEffect(() => {
    userStore.getUserData()
  }, [])

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path='/' element={<HocPrivateRoute />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to='/home' replace />} />
            <Route path="home" element={<Home />} />
            <Route path='moder/*' element={<ModerPage />} />
            <Route path='content/lesson/:id' element={<ContentLessonPage />} />
            <Route path='users' element={<UsersPage />} />
            <Route path='docs' element={<DocsPage />} />
            <Route path='logs' element={<LogsPage />} />
            <Route path='suspicious' element={<SpiciousListPage />} />
            <Route path='groups' element={<GroupsPage />} />
          </Route>
        </Route>
      </Routes >
    </>
  );
}

export default App;
