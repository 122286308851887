import styled from "styled-components";

const TableHead = styled.th`
    vertical-align: middle;
    min-width: 100px;
    padding: 8px 15px;
    border-radius: 2px;
    background-color: #d7d7d7;
    border:1px solid #b4b4b4
`

export default TableHead;