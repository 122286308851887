import { ButtonClose, ButtonSuccess, ButtonWrap, ModalContentStyle, ModalStyle, Title } from "../styled";
import ReactDOM from "react-dom";

const DeleteModal = ({ onClose, onConfirm }) => {
    return ReactDOM.createPortal(
        <ModalStyle>
            <ModalContentStyle>
                <Title>Точно хотите удалить ссылку?</Title>
                <ButtonWrap>
                    <ButtonClose onClick={onClose}>Нет</ButtonClose>
                    <ButtonSuccess onClick={onConfirm}>Да</ButtonSuccess>
                </ButtonWrap>
            </ModalContentStyle>
        </ModalStyle>,
        document.body
    );
};

export default DeleteModal;