import styled from "styled-components"

const CardWrap = styled.li`
    display: flex;
    align-items: center;
    gap: 14px;
    list-style: none;
    padding: 20px 12px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    justify-content: space-between;
    background-color: #fff;

    &:hover {
        background: #E8F1FF;
    }
`

export default CardWrap