const ArrowIcon = ({ rotated, flip, width = "12px", height = "18px", openModal }) => {
    return (
        <svg onClick={openModal} style={{
            cursor: "pointer",
            transform: rotated ? (flip ? "rotate(-90deg)" : "rotate(90deg)") : "rotate(0deg)",
            transition: "transform 0.3s ease",
            width: width,
            height: height
        }} viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.45026L9.38394 7.41487C10.6419 8.30981 10.6101 10.1885 9.32271 11.0404L1 16.5479" stroke="#163C85" strokeWidth="2" />
        </svg>
    )
}

export default ArrowIcon;
