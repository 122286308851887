import styled from "styled-components";

const ModalContentStyle = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    display:flex;
    flex-direction:column;
    gap:20px;
`;

export default ModalContentStyle