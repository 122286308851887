import styled from "styled-components";

const SidebaContainer = styled.section`
    display: flex;
    flex-direction: column;
    max-width: 280px;
    min-width: 280px;
    height: 100%;
    padding: 10px;
    border-right: 1px solid #00000030;
`

export default SidebaContainer