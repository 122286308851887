import AttachmentRender from "./AttachmentRender/CustomAttachmentRerender";
import NestedList from "./NestedList/CustomNestedList";
import CodeBoxOutput from "./CodeBoxOutput/CustomCodeBoxOutput";
import TableOutput from "./TableOutput/CustomTable";
import CustomImage from "./Image/CustomImage";
import CustomAlert from "./CustomAlert/CustomAlert";

const renderers = {
    attaches: AttachmentRender,
    list: NestedList,
    code: CodeBoxOutput,
    table: TableOutput,
    image: CustomImage,
    warning: CustomAlert
};
export default renderers