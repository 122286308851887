import { api } from "api";
import config from "config"
// import mockData from "../mock/mockData";

let subjects = [
    { id: 1, subject: 'Математика' },
    { id: 2, subject: 'Физика' },
    { id: 3, subject: 'Химия' },
    { id: 4, subject: 'Биология' },
    { id: 5, subject: 'История' },
    { id: 6, subject: 'География' },
    { id: 7, subject: 'Информатика' },
    { id: 8, subject: 'Литература' },
    { id: 9, subject: 'Астрономия' },
    { id: 10, subject: 'Философия' },
    { id: 11, subject: 'Социология' },
    { id: 12, subject: 'Психология' },
    { id: 13, subject: 'Экономика' },
    { id: 14, subject: 'Право' },
    { id: 15, subject: 'Медицина' },
    { id: 16, subject: 'Фармацевтика' },
    { id: 17, subject: 'Искусство' },
    { id: 18, subject: 'Музыка' },
    { id: 19, subject: 'Журналистика' },
    { id: 20, subject: 'Политология' },
    { id: 21, subject: 'Лингвистика' },
    { id: 22, subject: 'Филология' },
    { id: 23, subject: 'Теология' },
    { id: 24, subject: 'Археология' },
    { id: 25, subject: 'Антропология' },
    { id: 26, subject: 'Генетика' },
    { id: 27, subject: 'Ботаника' },
    { id: 28, subject: 'Зоология' },
    { id: 29, subject: 'Экология' },
    { id: 30, subject: 'Гидрология' },
    { id: 31, subject: 'Метеорология' },
    { id: 32, subject: 'Климатология' },
    { id: 33, subject: 'Физическая культура' },
    { id: 34, subject: 'Туризм' },
    { id: 35, subject: 'Архитектура' },
    { id: 36, subject: 'Дизайн' },
    { id: 37, subject: 'Радиоэлектроника' },
    { id: 38, subject: 'Машиностроение' },
    { id: 39, subject: 'Энергетика' },
    { id: 40, subject: 'Авиация' },
    { id: 41, subject: 'Космонавтика' },
    { id: 42, subject: 'Навигация' },
    { id: 43, subject: 'Картография' },
    { id: 44, subject: 'Геодезия' },
    { id: 45, subject: 'Минералогия' },
    { id: 46, subject: 'Палеонтология' },
    { id: 47, subject: 'Токсикология' },
    { id: 48, subject: 'Патология' },
    { id: 49, subject: 'Ветеринария' },
    { id: 50, subject: 'Агроинженерия' }
];

export const getSubjects = () => {
    if (config.useMocks) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ data: subjects })
            }, 1000)
        })
    } else {
        return api.get('subjects');
    }
}

export const updateSubject = (id, subject) => {
    if (config.useMocks) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                subjects = subjects.map(item => item.id === id ? { ...item, subject } : item)
                resolve();
            }, 100)
        })
    } else {
        return api.put(`subjects/${id}`, { id, subject });
    }
}

export const removeSubject = (id) => {
    if (config.useMocks) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                subjects = subjects.filter(item => item.id !== id)
                resolve();
            }, 100)
        })
    } else {
        return api.delete(`subjects/${id}`);
    }
}

export const addSubject = (value) => {
    if (config.useMocks) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const id = subjects[subjects.length - 1].id + 1
                subjects = [...subjects, { id, subject: value }]
                resolve();
            }, 100)
        })
    } else {
        return api.post('subjects', { subject: value });
    }
}

export const getSubject = (id) => {
    if (config.useMocks) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                subjects = subjects.find(item => item.id === id)
                resolve();
            })
        })
    } else {
        return api.get(`subjects/${id}`);
    }
}