import React from 'react';
import { Figure, Img, ImgWrap, Figcaption } from './styled';

const CustomImage = ({ data, style, classNameNames, config }) => {
    return (
        // <div className={cn(cl.ImgWrap, data.stretched && cl.stretched)}>
        <ImgWrap
            $stretched={data.stretched}
        >
            <Figure
                $caption={data.caption}
                $background={data.withBackground}
                $border={data.withBorder}
            >
                <Img src={data.file.url} alt={data.caption || ''} />
            </Figure>
            {/* <figure             
                className={cn(
                    cl.figure,
                    data.caption && cl.caption,
                    data.withBackground && cl.Background,
                    data.withBorder && cl.border,
                    !data.stretched && cl.stretched,
                
                </figure> */}
            {
                data.caption &&
                <Figcaption>{data.caption}</Figcaption>
            }
        </ImgWrap>
        // </div>
    );
};
export default CustomImage;