import styled from "styled-components";

export const InputDefault = styled.input`
    ${'' /* max-width: 100%; */}
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    padding: 10px 16px;
    width: 100%;
    &:focus{
        outline: none;
        border: 1px solid #58aee7;
        box-shadow: 2px 2px 6px #58aee732;
    }
`