import styled from "styled-components";

const PopupContainer = styled.div`
  position: absolute;
  top: -140%;
  left: -1100%;
  z-index: 1200;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
`;

export default PopupContainer