import styled from "styled-components";

const LogContainer = styled.code`
    padding: 6px;
    background-color: #330000;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &::-webkit-scrollbar-track {
        background: transparent
    }
    &::-webkit-scrollbar-thumb {
        -webkit-appearance: none;
        background-color: #979797;
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        background: none;
        width: 8px;
    }
`

export default LogContainer;