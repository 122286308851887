import styled from "styled-components";

export const PageHeader = styled.div`
    max-width: 100%;
    border-bottom: 1px solid #dbdbdb;
    padding: 10px 30px;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 0;
`