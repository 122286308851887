import styled from "styled-components"

const CrossWrap = styled.div`
    position:absolute;
    top:5px;
    right:10px;
    background:#DDDDDD;
    padding:5px;
    font-size:10px;
    border-radius:5px;
    cursor:pointer;
`

export default CrossWrap;