// mockData.js
export const mockTransactions = [
    {
        id: '1',
        transactionId: '123',
        createdAt: '2023-09-18T12:00:00Z',
        reason: 'Подозрительная активность',
        transaction: {
            id: '123',
            coursID: '456',
            status: 'Проверка',
            sum: '1000',
            userId: 'user_1',
            cardId: 'card_1',
            createdAt: '2023-09-18T10:00:00Z',
            updateAt: '2023-09-18T11:00:00Z'
        }
    },
    {
        id: '2',
        transactionId: '124',
        createdAt: '2023-09-19T12:00:00Z',
        reason: 'Подозрительная сумма',
        transaction: {
            id: '124',
            coursID: '457',
            status: 'Отклонена',
            sum: '5000',
            userId: 'user_2',
            cardId: 'card_2',
            createdAt: '2023-09-19T10:00:00Z',
            updateAt: '2023-09-19T11:00:00Z'
        }
    },
];
