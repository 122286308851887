import React from 'react';
import userStore from 'store/userStore';
import { Button } from './styled';

const LogOutButton = ({ children }) => {
    return (
        <Button
            onClick={() => userStore.LogOut()}
        >
            {children}
        </Button>
    );
};

export default LogOutButton;