import React from 'react';
import { LogField, LogId, LogItem } from '../styled';
import { formatDate } from 'hooks';

const LogsList = ({ items }) => {

    if (!items || items.length === 0) {
        return <LogField>Нет логов</LogField>;
    }

    return (
        <>
            {
                items.map((item) => {
                    if (!item) {
                        return null;
                    }

                    return (
                        <LogItem key={item.id}>
                            <LogId>{item.id}</LogId>
                            <LogField>{item.userId}</LogField>
                            <LogField>{formatDate(item.timestamp, { month: '2-digit' })}</LogField>
                            <LogField>{item.ipAddress}</LogField>
                            <LogField>{item.requestUrl}</LogField>
                        </LogItem>
                    );
                })
            }
        </>
    );
};

export default LogsList;