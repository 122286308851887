import { getUserRequest } from "api/actions"
import { makeAutoObservable } from "mobx"
import { newLogIn } from "routes/pages/LoginPage/api"

class User {
    auth = false
    authIsReady = false
    data = {
        id: '',
        role_id: ''
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    LogOut() {
        this.auth = false;
        this.authIsReady = true
        this.data = {};
        localStorage.clear()
    }

    signIn() {
        this.authIsReady = true
        this.auth = true
    }

    getUserData() {
        this.authIsReady = false
        if (localStorage.getItem('token')) {
            getUserRequest({
                device_type_id: '1',
                platform: '123',
                geo: '144',
                system: '123'
            })
                .then((result) => {
                    this.data = { ...this.data, id: result.data.id, role_id: result.data.role_id }
                    if (!this.auth) {
                        this.signIn()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.authIsReady = true
                })
        }
        else {
            this.LogOut()
        }
    }

    setLocalStorageItem(key, value) {
        return new Promise((resolve) => {
            localStorage.setItem(key, value);
            resolve();
        });
    }

    async logIn(data) {
        try {
            const result = await newLogIn(data)
            await this.setLocalStorageItem('token', result.data.token)
            this.getUserData()
        } catch (error) {
            console.log(error)
        }
    }
}

export default new User()