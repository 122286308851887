import styled from "styled-components";

const ModalContainer = styled.div`
    ${'' /* position: absolute;
    top: calc(20% + 30px); // Это смещает список вниз относительно аккордеона
    left: 50%;
    transform: translate(-50%, 0); */}
    z-index: 1000;
    width: 800px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
`;

export default ModalContainer