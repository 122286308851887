import { observer } from "mobx-react";
import { CoursesList, UsersList } from ".";
import { Separator, StyledCoursesList, StyledUsersList, Wrapper } from "../styled";


const GroupContentWrapper = observer(({group}) => {

    return (
        <Wrapper>
            <StyledCoursesList>
                <CoursesList group={group} />
            </StyledCoursesList>
            <Separator />
            <StyledUsersList>
                <UsersList group={group} />
            </StyledUsersList>
        </Wrapper>
    )
})

export default GroupContentWrapper; 