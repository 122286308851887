import styled from "styled-components";
import { Link } from "react-router-dom";

const black = '#353535';
const gray = '#35353514';

const Button = styled(Link)`
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 6px;
    /* text-transform: uppercase; */
    /* border: 1px solid ${gray}; */
    font-weight: 600;
    color: ${black};
    font-size: 14px;
    background-color: ${({ $isActive }) => $isActive ? gray : 'transparent'};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    position: relative;

    ${({ $isActive }) => !$isActive && `
            &: hover {
            background-color: rgba(53, 53, 53, 0.040);
        }`
    }
`

export default Button;