import styled from "styled-components";

const FormWrap = styled.div`
    ${'' /* width: 100%; */}
    height: min-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
`;

export default FormWrap