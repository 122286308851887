import { IconClose } from 'ui/components/IconClose';
import IconWrap from './../ConfirmationPopup/styled/IconWrap';
import { Text } from '../AdvancedSettingsPopup/styled';
import ButtonsWrap from '../ConfirmationPopup/styled/ButtonsWrap';
import { AddGroup } from 'routes/pages/GroupsPage/styled';
import { AddUserButton } from '../UserPopup/styled';
import { useState } from 'react';
import { ContentWrap, ExtensiveSettingsWrap } from './styled';
import groupsStore from 'store/groupsStore';

const ExtensiveSettings = ({ id, text, onClose }) => {
    const [localOptions, setLocalOptions] = useState(groupsStore.options);

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setLocalOptions(prevLocalOptions => ({
            ...prevLocalOptions,
            name: groupsStore.selectedGroup.name,
            numberOfParticipants: groupsStore.selectedGroup.numberOfParticipants,
            [id]: checked
        }));
    };

    const handleSave = async () => {
        await groupsStore.updateGroup(id, localOptions);
    };

    return (
        <ExtensiveSettingsWrap>
            <IconWrap>
                <p>{text}</p>
                <button onClick={onClose}>
                    <IconClose />
                </button>
            </IconWrap>
            <ContentWrap>
                <Text $color="#163C85">Доступ пользователей к курсам</Text>
                <input
                    type="checkbox"
                    id="isaccess"
                    checked={localOptions.isaccess}
                    onChange={handleCheckboxChange}
                />
            </ContentWrap>
            <ButtonsWrap>
                <AddGroup onClick={onClose}>Отменить</AddGroup>
                <AddUserButton onClick={handleSave}>Сохранить</AddUserButton>
            </ButtonsWrap>
        </ExtensiveSettingsWrap>
    )
}

export default ExtensiveSettings;
