import styled from "styled-components";

export const CardContentInfo = styled.div`
max-width: 100%;
/* background-color: #fff; */
/* padding: 6px; */
border-radius: 6px;
display: flex;
flex-direction: row;
gap: 20px;
align-items: flex-start;
justify-content: center;
`