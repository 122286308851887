const usePassEncrypt = (pass) => {
    const Encript = (pass) => {
        var numPassword = '';
        for (let i = 0; i < pass?.length; i++) {
            var charCode = pass.charCodeAt(i);
            numPassword += (charCode < 100 ? '0' : '') + charCode
        }

        return numPassword;
    }
    return Encript

};

export default usePassEncrypt;