import axios from "axios";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
})

api.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    return config
  }
)
api.interceptors.response.use(
  (config) => {
    return config
  },

  async (error) => {
    const originalRequest = { ...error.config, _isRetry: true };
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/jwt/refreshjwt`);
        localStorage.setItem("token", response.data.token);
        return api.request(originalRequest);
      } catch (error) {
      }
    }
    throw error;
  }
);