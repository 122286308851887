import styled from "styled-components";

const LinkOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 15; /* z-index для второго Overlay */
`;

export default LinkOverlay