import { styled } from 'styled-components';

const DateRangeWrap = styled.div`
display: flex;
align-items: center;
border-radius: 20px;
padding: 8px 12px;
${'' /* max-width: 250px; */}
`;

export default DateRangeWrap