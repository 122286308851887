import styled from "styled-components";
import { AddGroup, CardWrap, Email, RemoveButton } from "../styled";
import { api } from "api";
import AddGroupWrap from "../styled/AddGroupWrap";
import { PlusIcon } from ".";
import groupsStore from "store/groupsStore";

export const CardImage = styled.img`
width: 48px;
height: 48px;
border-radius: 50%;
object-fit: cover;
`;

export const CardContent = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
margin-left: 10px;
gap:8px;
`;

export const AddGroupButton = styled.button`
color:#163C85;
cursor:pointer;
`

const CardPopup = ({ fetchData, item, type }) => {
    const isCourse = type === "course";
    const imgSrc = isCourse ? item.course?.img : item.user?.img || './../img/Avatar.jpg';
    const label = isCourse ? item.label : item.user_name;
    const email = !isCourse ? item.email : null;

    const handleAdd = async () => {
        if (isCourse) {
            await groupsStore.addNewCourse(groupsStore.selectedGroup.id, item.id);
        } else {
            await groupsStore.addNewUser(groupsStore.selectedGroup.id, item.id);
        }
        fetchData(type, groupsStore.selectedGroup.id);
    };

    return (
        <CardWrap key={isCourse ? item.courseId : item.id}>
            <CardImage src={imgSrc} alt={label} />
            <CardContent>
                <p style={{ fontWeight: 'bold' }}>{label}</p>
                {email && <Email>{email}</Email>}
            </CardContent>
            <AddGroupWrap color="#E8F1FF">
                <PlusIcon />
                {
                    type === "course"
                        ? <AddGroupButton onClick={() => handleAdd()}>Добавить</AddGroupButton>
                        : <AddGroupButton onClick={() => handleAdd()}>Добавить</AddGroupButton>
                }
            </AddGroupWrap>

        </CardWrap>
    );
};

export default CardPopup;