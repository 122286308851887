import styled from "styled-components"

const FormWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #e8e8eb;
    padding:24px 0px;
    white-space: nowrap;
    cursor: pointer;
`

export default FormWrap