const ArrowIconOutlited = ({ rotated, toggleExpand }) => {
    return (
        <svg onClick={toggleExpand} style={{
            cursor: "pointer",
            transform: rotated ? "rotate(90deg)" : "rotate(0deg)", // Поворачиваем стрелку при rotated === true
            transition: "transform 0.3s ease",
        }} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2.98072L3.1898 2L9.67036 7.34495C9.77483 7.43059 9.85773 7.53244 9.9143 7.64462C9.97088 7.7568 10 7.87711 10 7.99861C10 8.12012 9.97088 8.24042 9.9143 8.3526C9.85773 8.46479 9.77483 8.56663 9.67036 8.65227L3.1898 14L2.00112 13.0193L8.08359 8L2 2.98072Z" fill="#D1D1D1" />
        </svg>

    )
}

export default ArrowIconOutlited