import { useEffect, useState } from 'react';
import { Overlay } from '../OverlayPopup/styled';
import { LinkOverlay, SettingWrap, SettingsLine, Text } from './styled';
import LinksPopup from '../LinksPopup/LinksPopup';
import { ExtensiveSettings } from '../ExtensiveSettings';
import groupsStore from 'store/groupsStore';
import { useModal } from 'routes/pages/GroupsPage/hooks';
import { api } from 'api';

const AdvancedSettingsPopup = ({ id, showModal,
    handleCloseModal, group, openConfirmModal
}) => {
    const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
    const { isVisible, openModal, closeModal } = useModal();
    const [lastCopiedId, setLastCopiedId] = useState(null);
    const isLinkCopied = lastCopiedId === group.id;
    const elements = [
        { id: 1, name: 'Расширенные настройки', onClick: () => setIsSettingsModalVisible(true) },
        {
            id: 2,
            name: isLinkCopied ? 'Настройки ссылки' : 'Сформировать ссылку',
            onClick: () => openModal()
        },
        { id: 3, name: 'Создать отчет XML', onClick: () => handleGetReport(id) },
        { id: 4, name: 'Удалить', onClick: () => handleDeleteGroup(id) },
    ]

    const handleCopyLink = (linkId) => {
        setLastCopiedId(linkId);
    };

    function handleGetReport(id) {
        api.get(`groups/get-report`, {
            headers: {
                "Accept": "application/xml"
            },
            params: { groupId: id }
        })
            .then((result) => {
                const file = new Blob([result.data], { type: 'application/xml' });
                const url = URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'groups.xml';
                a.click();
            })
    }

    const handleDeleteGroup = async (groupId) => {
        try {
            await groupsStore.deleteGroup(groupId)
        } catch (error) {
            console.error("Error deleting group", error);
        }
    }

    if (!showModal) return null;

    return (
        <>
            <Overlay onClick={handleCloseModal} style={{ zIndex: 5 }} />
            <SettingWrap style={{ zIndex: 10 }}>
                {elements.map(({ id, name, onClick }) => (
                    <SettingsLine key={id}>
                        <Text $color={name !== 'Удалить' ? '#163C85' : undefined} onClick={onClick}>
                            {name}
                        </Text>
                    </SettingsLine>
                ))}
            </SettingWrap>

            {isVisible && (
                <LinksPopup
                    handleCloseSettingsPopup={handleCloseModal}
                    handleCloseIsLinkModalVisible={closeModal}
                    id={id}
                    handleCopyLink={handleCopyLink}
                    handleCloseLinkModal={closeModal}
                />
            )}

            {isSettingsModalVisible && (
                <ExtensiveSettings
                    id={id}
                    text="Расширенные настройки"
                    onClose={() => setIsSettingsModalVisible(false)}
                />
            )}
        </>
    );
};

export default AdvancedSettingsPopup;
