import styled from "styled-components"

const Text = styled.p`
    border: 1px solid black;
    border-radius: 10px;
    white-space:nowrap;
    padding: 10px;
    height: 40px;
`

export default Text