import React from 'react';
import { TableHead, TableRow } from './styled';

const TableOutput = ({ data }) => {

    const columnNames = data.content[0];
    return (
        <table  >
            {
                data.withHeadings
                    ?
                    <thead>
                        <tr >
                            {columnNames?.map((columnName, index) =>
                                <TableHead
                                    key={index}
                                >
                                    {columnName.replace('&nbsp;', " ")}
                                </TableHead>
                            )}
                        </tr>
                    </thead>
                    :
                    null
            }
            <tbody>
                {
                    data?.content.map((row, index) => (
                        index !== 0 ?
                            <tr style={{ backgroundColor: 'white' }} key={index}>
                                {
                                    Array.isArray(row) && row.length > 1 &&
                                    row.map((columnValue, i) =>
                                        <TableRow
                                            key={i}
                                        >
                                            {columnValue.replace('&nbsp;', " ")}
                                        </TableRow>
                                    )
                                }
                            </tr>
                            :
                            null
                    ))
                }
            </tbody>
        </table>



    );
};

export default TableOutput;

