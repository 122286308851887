import FormWrap from "./styled/FormWrap";
import { FieldLabel, InputDefault } from "ui/styled";
import { GreenButton } from "../Buttons";

const Form = ({ onEvent, addingValue, setAddingValue }) => {
    return (
        <FormWrap>
            <FieldLabel>Добавления направления</FieldLabel>
            <InputDefault value={addingValue} onChange={(e) => setAddingValue(e.target.value)} type="text" placeholder="Введите текст" />
            <GreenButton onClick={onEvent}>Добавить</GreenButton>
        </FormWrap>
    )
}

export default Form