import styled from "styled-components";

export const InputFile = styled.input`
opacity: 0;
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
min-width: 100%;
min-height: 100%;
cursor: pointer;
`;