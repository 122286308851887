import styled from "styled-components";

const HeaderWrap = styled.div`
height: min-content;
display: flex;
flex-direction: row;
gap: 10px;
align-items: center;
justify-content: center;
background-color: #f1f1f1;
padding: 8px 0;
width: 100%;
`

export default HeaderWrap;