const formatDate = (date, { month, day, hour, minute, second, year } = {}) => {
  /* Функция возвращает отформатированное время в формате "00 январь 2024 в 00:00:00", если нет других параметров
  * Для смены параметров даты нужно передать нужные параметры вторым аргументом новые свойства {month, day, hour, minute, second, year}
  * Для отключения вывода определенных параметров передать вторым параметром {month: false, day: false, hour: false, minute: false, second: false, year: false}
  */

  const obj = { month, day, hour, minute, second, year }

  var options = {
    // era: 'long',
    year: year ?? 'numeric',
    month: month ?? 'long',
    day: day ?? 'numeric',
    // weekday: 'long',
    // timezone: 'UTC',
    hour: hour ?? 'numeric',
    minute: minute ?? 'numeric',
    second: second ?? 'numeric',
    hour12: false
  };

  for (var prop in obj) {
    if (obj[prop] === false) delete options[prop]
  }

  return date ? new Date(date).toLocaleDateString('ru', options) : date
}

export default formatDate;