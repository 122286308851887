import styled from "styled-components";

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b399d;
  border-radius: 5px;
  padding: 5px 10px;
  height: 40px;
  margin-right: -5px;
  z-index:10;
`;

export default Icon;