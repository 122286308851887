import { GreenButton } from "ui/components/Buttons"
import { FileUploadButton } from "."

const TableRow = ({ label, file, fileType, handleFileChange, sendApiFile }) => {
    return (
        <tr>
            <td>{label}</td>
            <td>{file ? file.name : ''}</td>
            <td>
                <FileUploadButton file={file} fileType={fileType} handleFileChange={handleFileChange} />
            </td>
            <td>
                {
                    file ? <GreenButton onClick={() => sendApiFile(fileType)}>Сохранить</GreenButton> : <GreenButton disabled>Сохранить</GreenButton>
                }
            </td>
        </tr>
    )
}

export default TableRow