import React, { useState } from 'react';
import { CardDefault } from 'ui/components/Cards';
import { BlueButton, GreenButton, RedButton } from 'ui/components/Buttons';
import formatDate from 'hooks/formatDate';
import { banUser, getLogsById } from '../api';
import MainPopup from 'ui/Popups/MainPopup';
import { LogsTerminal } from 'routes/pages/LogsPage/ui';
import { useModal } from 'routes/pages/GroupsPage/hooks';
import { DenialPopup } from 'ui/Popups/DenialPopup';

const UserCard = ({ el }) => {
    const { isVisible, openModal, closeModal } = useModal();
    const [error, setError] = useState({ status: false, message: 'Ошибка запроса' })
    const [logs, setLogs] = useState({ status: false, content: [] })
    const [fields, setFields] = useState(
        [
            { label: 'ID', value: el.id },
            { label: 'Имя', value: el.user_name },
            { label: 'Фамилия', value: el.user_surname },
            { label: 'Отчество', value: el.user_patronymic },
            { label: 'Почта', value: el.mail },
            { label: 'Роль', value: el.role_id },
            { label: 'День Рождения', value: formatDate(el.user_birthday, { hour: false, minute: false, second: false }) },
            { label: 'Пол', value: el.user_sex },
            { label: 'Бан', value: el.is_banned },
        ]
    )

    const handleBanUser = async (params, comment) => {
        try {
            await banUser(params, comment);
            setFields(prevState => prevState.map(e => {
                if (e.label === 'Бан') {
                    return { ...e, value: params.status };
                }
                return e;
            }));

            closeModal();

        } catch (error) {
            setError({ ...error, status: true });
            closeModal();
        }
    };


    return (
        <CardDefault
            fields={fields}
            buttons={
                <>
                    {
                        fields.find(u => u.label === 'Бан').value
                            ?
                            <GreenButton onClick={() => handleBanUser({ id: fields?.find(e => e.label === 'ID').value, status: false })}>
                                Разбанить
                            </GreenButton >
                            : (
                                <>
                                    {
                                        isVisible && (
                                            <DenialPopup type={"user"} onClose={closeModal} handleBanUser={handleBanUser} text="Комментарий" params={{ id: fields?.find(e => e.label === 'ID').value, status: true }} />
                                        )
                                    }
                                    <RedButton onClick={() => openModal()}>
                                        Забанить
                                    </RedButton >
                                </>
                            )
                    }
                    <BlueButton onClick={() => {
                        getLogsById(el.id)
                            .then(result => setLogs({ ...logs, status: true, content: result.data }))
                            .catch(error => setLogs({ ...logs, content: error }))
                    }}>
                        Логи
                    </BlueButton>
                </>
            }
            popup={
                <MainPopup
                    width={logs.status && '1000px'}
                    isOpened={error.status || logs.status}
                    onClose={() => { setError({ ...error, status: false }); setLogs({ ...logs, status: false }) }}
                >
                    {
                        error.status ?
                            <h3>{error.message}</h3>
                            : logs.status ?
                                <>
                                    <p>Пользователь ID: {el.id}</p>
                                    <LogsTerminal items={logs.content} />
                                </>
                                : null
                    }
                </MainPopup>
            }
        />

    );
};

export default UserCard;