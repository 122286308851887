import { CalendarIcon } from ".";
import { DateRangeWrap, Icon, Text } from "../styled";

const DateRange = () => {
    return (
        <DateRangeWrap>
            <Icon>
                <CalendarIcon />
            </Icon>
            <Text>05/10/2020 - 05/11/2020</Text>
        </DateRangeWrap>
    );
};

export default DateRange;