import { getSubjects, removeSubject, updateSubject, addSubject, getSubject } from "routes/pages/Home/api";
import { makeAutoObservable } from "mobx";

class Subject {

    subjects = [];
    totalPages = 0;
    constructor() {
        makeAutoObservable(this)
    }

    setSubjects(data) {
        this.subjects = data;
    }

    async loadSubjects() {
        let { data } = await getSubjects();
        this.setSubjects(data);
    }

    setSubjects(data) {
        this.subjects = data;
    }
    async removeSubject(id) {
        await removeSubject(id);
        await this.loadSubjects();
    }

    async updateSubject(id, value) {
        await updateSubject(id, value);
        await this.loadSubjects();
    }

    async addSubject(addingValue) {
        await addSubject(addingValue);
        await this.loadSubjects();
    }

    async getSubject(id) {
        let { data } = await getSubject(id);
        return data;
    }
}

export default new Subject()