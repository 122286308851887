import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import subjectsStore from 'store/subjectsStore';
import { CrossWrap } from '../LinksPopup/styled';
import { RxCross1 } from "react-icons/rx";
import { api } from 'api';
import { Popup, SubjectItem, SubjectList } from './styled';
import { debounce } from 'helpers/functions';
import groupsStore from 'store/groupsStore';

const SubjectFilterPopup = observer(({ id, handleCloseModal, show }) => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  useEffect(() => {
    const loadSubjects = async () => {
      await subjectsStore.loadSubjects();
      setSubjects(subjectsStore.subjects);
    };

    loadSubjects();
  }, []);

  const sendRequest = useCallback(
    debounce(async (updatedSelectedSubjects) => {
      try {
        if (updatedSelectedSubjects.length > 0) {
          await groupsStore.coursesNotGroupFilters(id, updatedSelectedSubjects)
        } else {
          await groupsStore.loadUsersNotGroup(id);
        }
      } catch (error) {
        console.error('Ошибка при загрузке курсов:', error);
      }
    }, 300),
    []
  );

  const handleItemClick = (subject) => {
    let updatedSelectedSubjects;

    if (selectedSubjects.includes(subject.id)) {
      updatedSelectedSubjects = selectedSubjects.filter(id => id !== subject.id);
    } else {
      updatedSelectedSubjects = [...selectedSubjects, subject.id];
    }

    setSelectedSubjects(updatedSelectedSubjects);

    sendRequest(updatedSelectedSubjects);
  };

  if (!show) return null;

  return (
    <>
      <Popup>
        <SubjectList>
          {subjects.length > 0 ? (
            subjects.map((subject) => (
              <div style={{ position: 'relative' }} key={subject.id}>
                <SubjectItem
                  onClick={() => handleItemClick(subject)}
                  className={selectedSubjects.includes(subject.id) ? 'selected' : ''}
                >
                  {subject.subject}
                </SubjectItem>
              </div>
            ))
          ) : (
            <p>Нет предметов</p>
          )}
          <CrossWrap onClick={handleCloseModal}>
            <RxCross1 />
          </CrossWrap>
        </SubjectList>
      </Popup>
    </>
  );
});

export default SubjectFilterPopup;
