import config from 'config';
import { mockTransactions } from './../mock/mockData';
import { api } from 'api';

export const getSuspiciousTransactions = async (params) => {
    if (config.useMocks) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ data: { data: mockTransactions } });
            }, 500);
        });
    } else {
        return api.get(`transaction/suspicious`, { params });
    }
};

export const exportTransactionsToXML = async (ids) => {
    if (config.useMocks) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ data: '<xml>Mock XML Data</xml>' });
            }, 500);
        });
    } else {
        return api.get(`transaction/export`, {
            params: { id: JSON.stringify(ids) }
        });
    }
};
