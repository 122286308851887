import React, { useState, useEffect } from 'react';
import { GroupWrap, InputDate, InputSearch, InputWrap, AddGroup, ContentWrap, SearchIconWrap, ListGroupWrap } from './styled';
import { DateRange, ListGroups, NotElements, PlusIcon } from './components';
import AddGroupWrap from './styled/AddGroupWrap';
import { PageWrap } from '../DocsPage/styled';
import { GroupPopup } from 'ui/Popups/GroupPopup';
import groupsStore from 'store/groupsStore';
import { observer } from 'mobx-react';
import { useFetchGroups, useModal } from './hooks';

const GroupsPage = observer(() => {
    const { loading, error } = useFetchGroups();
    const { isVisible, openModal, closeModal } = useModal();

    const handleGroupSelect = (group) => {
        groupsStore.setSelectedGroup(group);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <PageWrap>
            <ContentWrap>
                <InputWrap>
                    <InputSearch disabled placeholder='Поиск' />
                    <DateRange />
                </InputWrap>
                <GroupWrap>
                    <p>Список групп</p>
                    <AddGroupWrap>
                        <PlusIcon />
                        <AddGroup onClick={openModal}>Добавить группу</AddGroup>
                    </AddGroupWrap>
                    <GroupPopup show={isVisible} onClose={closeModal} text={"Создание группы"} label={"Название группы"} placeholder={"Введите название группы"} />
                </GroupWrap >
                <ListGroupWrap>
                    {
                        groupsStore.groups.length > 0
                            ? groupsStore.groups.map(group =>
                                <ListGroups
                                    key={group.id}
                                    onGroupSelect={handleGroupSelect}
                                    group={group}
                                />
                            )
                            : <NotElements text={"Группы отсутствуют"} />
                    }
                </ListGroupWrap>
            </ContentWrap>
        </PageWrap>
    );
});

export default GroupsPage;