import { GreenButton } from "ui/components/Buttons";
import { FormWrap, InputDate, Label } from "../styled";

const FormDate = ({ date, setDate, handleExport }) => {
    return (
        <FormWrap>
            <Label>
                <span>С</span>
                <InputDate type='date' onChange={(e) => setDate({ ...date, from: e.target.value })} />
            </Label>
            <Label>
                <span>По</span>
                <InputDate type='date' onChange={(e) => setDate({ ...date, to: e.target.value })} />
            </Label>
            <GreenButton onClick={handleExport}>Экспорт в XML</GreenButton>
        </FormWrap>
    )
}

export default FormDate;