import React from 'react';
import { Button } from 'ui/styled';

const RedButton = ({ background, hover, children, ...props }) => {
    return (
        <Button
            $background={background ? background : '#be4141'}
            $hover={hover ? hover : '#d34444'}
            {...props}
        >
            {children}
        </Button >
    );
};

export default RedButton;